import React, { Component } from 'react';

import TextFieldGroup from '../common/TextFieldGroup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import Validator from 'validator'

import agent from "../../agent";



function validateInput(data) {
    let errors = {};

    if (Validator.isEmpty(data.username)) {
        errors.username = 'This field is required';
    }

    if (Validator.isEmpty(data.email)) {
        errors.email = 'This field is required';
    }

    if (!errors.email && !Validator.isEmail(data.email)) {
        errors.email = 'Invalid Email.';
    }

    if (Validator.isEmpty(data.password)) {
        errors.password = 'This field is required';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    }

}

const mapStateToProps = state => ({
    ...state.school,
    token: state.common.token,
    currentUser: state.common.currentUser,
});
class SchoolAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            password: '',
            userType: 'schooladmin',
            errors: {},
            isLoading: false,
        };

    }

    isValid = () => {
        const { errors, isValid } = validateInput(this.state);

        if (!isValid) {
            this.setState({ errors });
        }

        return isValid;
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.handleSubmition()
    }
    handleSubmition = () => {
        if (this.isValid()) {
            this.setState({ errors: {}, isLoading: true });
            agent.School.createAdmin(this.state).then((result) => {
                this.setState({
                    username: '',
                    email: '',
                    password: '',
                    errors: {},
                    isLoading: false,
                });
                this.props.fetchUserData()
                this.props.togglePopup()
            }).catch((err) => {
                let errors = JSON.parse(err.response.text)
                this.setState({ errors: errors.errors, isLoading: false })
            })
        }
    }

    onChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { errors, username, email, password, isLoading } = this.state;
        const { togglePopup, currentUser } = this.props;

        return (<form autoComplete="off" onSubmit={this.onSubmit}>
            {errors.form && <div className={"alert alert-danger"}>{errors.form}</div>}
            <TextFieldGroup
                field="username"
                label="Username"
                value={username}
                error={errors.username}
                onChange={this.onChange}
            />
            <TextFieldGroup
                field="email"
                label="Email"
                value={email}
                error={errors.email}
                onChange={this.onChange}
            />
            <TextFieldGroup
                field="password"
                label="Password"
                value={password}
                error={errors.password}
                onChange={this.onChange}
            />
            <div className={'right-footer'}>
                <button type="submit" className={cx("btn at-themebtn")} disabled={isLoading}>{currentUser.role === 'admin' ? "Create School Admin" : 'Create User'}</button>
                <button type="button" className={cx("btn at-themebtn")} onClick={togglePopup}>Cancel</button>
            </div>
        </form>);
    }
}

SchoolAccount.propTypes = {
    togglePopup: PropTypes.func.isRequired,
    fetchUserData: PropTypes.func.isRequired
};

export default connect(mapStateToProps, {})(SchoolAccount);