import ListErrors from './ListErrors';
import React, { Fragment } from 'react';
import agent from '../agent';
import { connect } from 'react-redux';
import TextFieldGroup from './common/TextFieldGroup'

import {
  ADD_TAG,
  GOOGLE_APP_PAGE_LOADED,
  REMOVE_TAG,
  APP_SUBMITTED,
  GOOGLE_APP_PAGE_UNLOADED,
  UPDATE_FIELD_GOOGLE_APP,
  UPDATE_FIELD_GOOGLE_APP_CHECKBOX
} from '../constants/actionTypes';

const mapStateToProps = state => ({
  ...state.googleApp
});

const mapDispatchToProps = dispatch => ({
  onAddTag: () =>
    dispatch({ type: ADD_TAG }),
  onLoad: payload =>
    dispatch({ type: GOOGLE_APP_PAGE_LOADED, payload }),
  onRemoveTag: tag =>
    dispatch({ type: REMOVE_TAG, tag }),
  onSubmit: payload =>
    dispatch({ type: APP_SUBMITTED, payload }),
  onUnload: payload =>
    dispatch({ type: GOOGLE_APP_PAGE_UNLOADED }),
  onUpdateField: (key, value) =>
    dispatch({ type: UPDATE_FIELD_GOOGLE_APP, key, value }),
  onUpdateCheckboxField: (key, value) =>
    dispatch({ type: UPDATE_FIELD_GOOGLE_APP_CHECKBOX, key, value })
});

// let k12 = [
//   { appId: "co.brainly", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "co.gradeup.k12", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.aaglodapps.sciencenotes10thstd", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.Abdullah_Jillani.eLearnPunjabCONTENT", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.anencryptedmind.m.sciencedictionary", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.appblend.g2048", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.appybuilder.dilawarkmr.Love", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.biomagzine.jaikalki.class10ncertscisolution", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.biomagzine.jaikalki.physics12", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.bnepal.physics12", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.buta.caculator", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.civitas.quantumphysics", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.class12.ncertnotes", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.damtechdesigns.quiz.science", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.edupad.app.grade4.science", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.edupad.app.grade5.science", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.edupad.app.grade7.math", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.edupower.abiva", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.etasmgk.ogr", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.extramarks.scienceexperiment", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.Extramarks.Smartstudy", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.farlex.dictionary.science", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.gerund.hubphysicalscience", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.gerund.hubsocialsciences", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.gerund.shsbiology1", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.gerund.shschem1", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.gerund.shscreativewriting", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.gerund.shsearth", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.gerund.shsearthscience", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.gerund.shsgenmath", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.gerund.shskomunikasyon", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.gerund.shsoralcom", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.gerund.shsphysicalscience", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.gerund.shssocialscience", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.gktalk.general_science", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.gktalk.imran", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.gktalk.science_questions_answers", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.gktalk.sciencehindi_class_10", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.goodbarber.stemkids", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.google.android.apps.forscience.whistlepunk", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.idz.science.games.for.kids.first.grade", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.jaindev.science", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.kevinbradford.games.fifthgrade", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.kevinbradford.games.firstgrade", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.kevinbradford.games.fourthgrade", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.kevinbradford.games.secondgradefree", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.kevinbradford.games.sixthgrade", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.lalrajiv.science_quiz", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.learninga_z.onyourown", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.loreapps.general.science.book", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.mathtutordvd.mathtutor.mathtutor", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.meritnation.homework", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.mgh.android.connected", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.mheducation.cedmobile", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.mhsegsolutions.InspireScience3D", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.movinapp.facts.science", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.oksedu.marksharks.cbse.g09.s02", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.omeducation.cbseclass12", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.ozternapps.videos.grade1to12", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.playquiz2win.science.quiz", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.quizizz_mobile", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.quizmaster.science", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.quizmine.gradeeightscience", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.quizmine.gradefourscience", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.quizmine.gradesevenscience", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.quizmine.gradesixscience", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.quizover.app.course.col10322", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.robomateplus.mh_hsc_jee_neet_aiims", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.RomSoft.Quiz_Science_MLP", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.sriram.gk.telugu.generalscience", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.stamford.app.game.education.learn.physics.fragment.masterdetailflow", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.studentzoneapps.hindiclass12physics", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.TCYonline.android.TCY_K12", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.techzone.higher.experiment", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.topperscience.chemistryclass12topperscience", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.vedantu.ncertsolutions", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.websmith.oyeexams", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "com.world.general.science.knowledge.book", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "droidudes.mcq.mania", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "droidudes.physics.mcq.trivia.quiz", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
//   { appId: "ek.generalsciencedisha", tagList: ["K-12 Science & Tech"], platform: ["TouchIT Rapide LED"] },
// ]


class GoogleApp extends React.Component {
  constructor() {
    super();

    const updateFieldEvent = key => ev => this.props.onUpdateField(key, ev.target.value);
    const updateCheckboxFieldEvent = key => ev => this.props.onUpdateCheckboxField(key, ev.target);

    this.changeAppId = updateFieldEvent('appId');
    this.changeTagInput = updateFieldEvent('tagInput');
    this.changePlatforms = updateCheckboxFieldEvent('platform');

    this.watchForEnter = ev => {
      if (ev.keyCode === 13) {
        ev.preventDefault();
        this.props.onAddTag();
      }
    };

    this.removeTagHandler = tag => () => {
      this.props.onRemoveTag(tag);
    };

    this.submitForm = ev => {
      ev.preventDefault();
      const googleApp = {
        appId: this.props.appId,
        tagList: this.props.tagList,
        platform: this.props.platform,
      };

      const slug = { slug: this.props.appSlug };
      const promise = this.props.appSlug ?
        agent.Applications.updateGoogleApp(Object.assign(googleApp, slug)) :
        agent.Applications.createGoogleApp(googleApp);

      this.props.onSubmit(promise);
    };
  }
  // timer(ms) {
  //   return new Promise(res => setTimeout(res, ms));
  // }

  // async componentDidMount() {
  //   for (let app of k12) {
  //     agent.Applications.createGoogleApp(app);
  //     await this.timer(3000);
  //     console.log(app)
  //   }
  // }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.slug !== nextProps.match.params.slug) {
      if (nextProps.match.params.slug) {
        this.props.onUnload();
        return this.props.onLoad(agent.Applications.get(this.props.match.params.slug));
      }
      this.props.onLoad(null);
    }
  }

  componentWillMount() {
    if (this.props.match.params.slug) {
      return this.props.onLoad(agent.Applications.get(this.props.match.params.slug));
    }
    this.props.onLoad(null);
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    return (
      <Fragment>
        <div className="tg-sectiontitle at-paddonlybottom">
          <h2><span>Add New Google Application</span></h2>
        </div>

        <ListErrors errors={this.props.errors}></ListErrors>

        <form>
          <div className={'row'}>
            <div className={'col-xs-12 col-sm-6 col-md-6 col-lg-6'}>
              <div className={'form-group'}>
                <label>Google Application Id</label>
                <TextFieldGroup
                  label="Google Application Id"
                  onChange={this.changeAppId}
                  value={this.props.appId}
                  field="appId"
                  type="text"
                />
              </div>
            </div>
            <div className={'col-xs-12 col-sm-6 col-md-6 col-lg-6'}>
              <div className={'form-group'}>
                <label>Application tags</label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Enter tags"
                  value={this.props.tagInput}
                  onChange={this.changeTagInput}
                  onKeyUp={this.watchForEnter} />
                <div className="tag-list">
                  {
                    (this.props.tagList || []).map(tag => {
                      return (
                        <span className="nsg-tag" key={tag}>
                          {tag}
                          <i className="fa fa-times-circle-o"
                            onClick={this.removeTagHandler(tag)}>
                          </i>
                        </span>
                      );
                    })
                  }
                </div>
              </div>
            </div>

            <div className={'col-xs-12 col-sm-12 col-md-12 col-lg-12'}>
              {/* <label>Supported Platforms</label> */}

              <div className={'col-xs-6 col-sm-6 col-md-6 col-lg-6'}>
                <div className={'form-group'}>

                  <div class="checkbox">
                    <label>
                      <input
                        onChange={this.changePlatforms}
                        type="checkbox"
                        value="TouchIT Y Series LED"
                      />
                      TouchIT Y Series LED
                  </label>
                  </div>
                </div>
              </div>

              <div className={'col-xs-6 col-sm-6 col-md-6 col-lg-6'}>
                <div className={'form-group'}>
                  <div class="checkbox">
                    <label>
                      <input
                        onChange={this.changePlatforms}
                        type="checkbox"
                        value="TouchIT Rapide LED"
                      />
                      TouchIT Rapide LED
                    </label>
                  </div>
                </div>
              </div>
            </div>


            <div className={'col-xs-12 col-sm-12 col-md-12 col-lg-12'}>
              <div className="form-group">
                <button
                  className="tg-btn pull-right"
                  type="button"
                  disabled={this.props.inProgress}
                  onClick={this.submitForm}>
                  Add Google App
                      </button>
              </div>
            </div>
          </div>
        </form>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApp);
