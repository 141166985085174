import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import s from './Popup.module.css'

class Popup extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        isOpen: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        title: '',
        isOpen: false,
    };

    render() {
        const {
            title,
            isOpen,
        } = this.props;
        return (
            <div className={cx(s["at-popupcontainer"], { [s['at-openpopup']]: isOpen })}>
                <div className={s["at-forgotpasswordpopup"]}>
                    <div className={s["at-popuphead"]}>
                        <h3>{title}</h3>
                    </div>
                    <div className={s["at-forgotcontent"]}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

export default Popup;