import { DASHBOARD_PAGE_LOAD, DASHBOARD_PAGE_UNLOAD } from "../constants/actionTypes";

export default (state = {}, action) => {
    switch (action.type) {
        case DASHBOARD_PAGE_LOAD:
            return {
                ...state,
                appsCount: action.payload[0].appsCount,
                usersCount: action.payload[0].usersCount
            }
        case DASHBOARD_PAGE_UNLOAD:
            return {}
        default:
            return state;
    }

}