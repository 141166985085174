export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const APP_SUBMITTED = 'APP_SUBMITTED';
export const SETTINGS_SAVED = 'SETTINGS_SAVED';
export const DELETE_APP = 'DELETE_APP';
export const SETTINGS_PAGE_UNLOADED = 'SETTINGS_PAGE_UNLOADED';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED';
export const HOME_PAGE_UNLOADED = 'HOME_PAGE_UNLOADED';
export const APP_PAGE_LOADED = 'APP_PAGE_LOADED';
export const APP_PAGE_UNLOADED = 'APP_PAGE_UNLOADED';
export const ADD_COMMENT = 'ADD_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const APP_FAVORITED = 'APP_FAVORITED';
export const APP_UNFAVORITED = 'APP_UNFAVORITED';
export const SET_PAGE = 'SET_PAGE';
export const APPLY_TAG_FILTER = 'APPLY_TAG_FILTER';
export const CHANGE_TAB = 'CHANGE_TAB';
export const PROFILE_PAGE_LOADED = 'PROFILE_PAGE_LOADED';
export const PROFILE_PAGE_UNLOADED = 'PROFILE_PAGE_UNLOADED';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const LOGIN_PAGE_UNLOADED = 'LOGIN_PAGE_UNLOADED';
export const REGISTER_PAGE_UNLOADED = 'REGISTER_PAGE_UNLOADED';
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';
export const EDITOR_PAGE_LOADED = 'EDITOR_PAGE_LOADED';
export const EDITOR_PAGE_UNLOADED = 'EDITOR_PAGE_UNLOADED';
export const ADD_TAG = 'ADD_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';
export const UPDATE_FIELD_AUTH = 'UPDATE_FIELD_AUTH';
export const UPDATE_FIELD_EDITOR = 'UPDATE_FIELD_EDITOR';
export const FOLLOW_USER = 'FOLLOW_USER';
export const UNFOLLOW_USER = 'UNFOLLOW_USER';
export const PROFILE_FAVORITES_PAGE_UNLOADED = 'PROFILE_FAVORITES_PAGE_UNLOADED';
export const PROFILE_FAVORITES_PAGE_LOADED = 'PROFILE_FAVORITES_PAGE_LOADED';

export const GOOGLE_APP_PAGE_LOADED = 'GOOGLE_APP_PAGE_LOADED';
export const GOOGLE_APP_PAGE_UNLOADED = 'GOOGLE_APP_PAGE_UNLOADED';
export const UPDATE_FIELD_GOOGLE_APP = 'UPDATE_FIELD_GOOGLE_APP';
export const UPDATE_FIELD_GOOGLE_APP_CHECKBOX = 'UPDATE_FIELD_GOOGLE_APP_CHECKBOX'


export const APP_PAGE_FAVORITED = 'APP_PAGE_FAVORITED';
export const APP_PAGE_UNFAVORITED = 'APP_PAGE_UNFAVORITED';

export const MY_APP_PAGE_LOADED = 'MY_APP_PAGE_LOADED';
export const MY_APP_PAGE_UNLOADED = 'MY_APP_PAGE_UNLOADED';
export const MY_APP_SUBMITTED = 'MY_APP_SUBMITTED';
export const MY_APP_ASYNC_START = 'MY_APP_ASYNC_START';
export const MY_APP_ADD_TAG = 'MY_APP_ADD_TAG';
export const MY_APP_REMOVE_TAG = 'MY_APP_REMOVE_TAG';
export const UPDATE_FIELD_MY_APP = 'UPDATE_FIELD_MY_APP';

export const UPDATE_FIELD_SEARCH_FORM = 'UPDATE_FIELD_SEARCH_FORM'
export const SEARCH_FORM_SUBMITTED = 'SEARCH_FORM_SUBMITTED'


export const DASHBOARD_PAGE_LOAD = 'DASHBOARD_PAGE_LOAD'
export const DASHBOARD_PAGE_UNLOAD = 'DASHBOARD_PAGE_UNLOAD'

export const USERS_LIST_PAGE_LOAD = 'USERS_LIST_PAGE_LOAD'
export const USERS_LIST_PAGE_UNLOAD = 'USERS_LIST_PAGE_UNLOAD'
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const SET_USER_PAGE = 'SET_USER_PAGE'

export const SCHOOL_LIST_PAGE_LOAD = 'SCHOOL_LIST_PAGE_LOAD'
export const SCHOOL_LIST_PAGE_UNLOAD = 'SCHOOL_LIST_PAGE_UNLOAD'