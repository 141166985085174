import { Link } from 'react-router-dom';
import ListErrors from './ListErrors';
import TextFieldGroup from './common/TextFieldGroup'
import React from 'react';
import agent from '../agent';
import { connect } from 'react-redux';
import { HOST } from '../config';
import {
  UPDATE_FIELD_AUTH,
  REGISTER,
  REGISTER_PAGE_UNLOADED
} from '../constants/actionTypes';

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
  onChangeEmail: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'email', value }),
  onChangePassword: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'password', value }),
  onChangeUsername: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'username', value }),
  onSubmit: (username, email, password) => {
    const payload = agent.Auth.register(username, email, password);
    dispatch({ type: REGISTER, payload })
  },
  onUnload: () =>
    dispatch({ type: REGISTER_PAGE_UNLOADED })
});

class Register extends React.Component {
  constructor() {
    super();
    this.changeEmail = ev => this.props.onChangeEmail(ev.target.value);
    this.changePassword = ev => this.props.onChangePassword(ev.target.value);
    this.changeUsername = ev => this.props.onChangeUsername(ev.target.value);
    this.submitForm = (username, email, password) => ev => {
      ev.preventDefault();
      this.props.onSubmit(username, email, password);
    }
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const errors = this.props.errors || {};
    const email = this.props.email;
    const password = this.props.password;
    const username = this.props.username;

    return (<div className="tg-fullscreen tg-fullbackground tg-signuparea" id="tg-fullbackground">
      <div style={{ position: 'absolute', zIndex: -1, top: 0, left: 0, bottom: 0, right: 0, overflow: 'hidden', backgroundSize: 'cover', backgroundColor: 'transparent', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 100%', backgroundImage: 'url("images/banner/img-04.jpg")' }}></div>

      <main id="tg-main" className="tg-main tg-haslayout">
        <div className="tg-formarea">
          <form onSubmit={this.submitForm(username, email, password)} className="tg-formtheme tg-formsignup">
            <h2>signup</h2>
            <fieldset>

              <TextFieldGroup
                error={errors.username}
                label="Username"
                onChange={this.changeUsername}
                value={username}
              />


              <TextFieldGroup
                error={errors.email}
                label="Email"
                onChange={this.changeEmail}
                value={email}
                field="email"
              />


              <TextFieldGroup
                error={errors.password}
                label="Password"
                onChange={this.changePassword}
                value={password}
                field="password"
                type="password"
              />

              <ListErrors errors={this.props.errors} />
              <div className="form-group text-center">
                <button type="submit" className="tg-btn" disabled={this.props.inProgress}>signup</button>
              </div>
              <div className="form-group">
                <ul className="tg-socialicons">
                  <li className="tg-facebook"><a href={HOST + '/api/login-via-facebook'}><i className="fa fa-facebook"></i></a></li>
                  <li className="tg-twitter"><a href={HOST + '/api/login-via-twitter'}><i className="fa fa-twitter"></i></a></li>
                  <li className="tg-googleplus"><a href={HOST + '/api/login-via-google'}><i className="fa fa-google"></i></a></li>
                  <li className="tg-vimeo"><a href={HOST + '/api/login-via-outlook'}><i className="fa fa-windows"></i></a></li>
                </ul>
              </div>
            </fieldset>
          </form>
          <div className="tg-description">
            <p>If You Have an Account? <Link to="/login">Signin</Link></p>
          </div>
        </div>
      </main>

    </div>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);