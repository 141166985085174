import app from './reducers/app';
import appList from './reducers/appList';
import auth from './reducers/auth';
import { combineReducers } from 'redux';
import common from './reducers/common';
// import editor from './reducers/editor';
import home from './reducers/home';
import profile from './reducers/profile';
import settings from './reducers/settings';
import googleApp from './reducers/googleApp';
import myApp from './reducers/myApp';
import dashboard from './reducers/dashboard';
import user from './reducers/user';
import school from './reducers/school';
import { routerReducer } from 'react-router-redux';

export default combineReducers({
  user,
  school,
  dashboard,
  app,
  appList,
  auth,
  common,
  home,
  profile,
  settings,
  googleApp,
  myApp,
  router: routerReducer
});
