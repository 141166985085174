import React from "react";
import Validator from "validator";
import isEmpty from "lodash/isEmpty";
import cx from "classnames";

import { Button, Card, Checkbox, Form, Input, message } from "antd";

import agent from "../agent";
// import { Link } from 'react-router-dom';
const FormItem = Form.Item;
const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;
// const Footer = () => {
function validateForm(data) {
  let errors = {};

  if (Validator.isEmpty(data.email)) {
    errors.email = "This field is required";
  }
  if (!errors.email && !Validator.isEmail(data.email)) {
    errors.email = "Email is invalid";
  }

  if (Validator.isEmpty(data.appName)) {
    errors.appName = "This field is required";
  }
  if (Validator.isEmpty(data.description)) {
    errors.description = "This field is required";
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}

function addOrRemove(array, value) {
  var index = array.indexOf(value);
  if (index === -1) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }
  return array;
}

const platformOptions = ["TouchIT Y Series LED", "TouchIT Rapide LED"];

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      appName: "",
      description: "",
      message: "",
      platform: [],
      errors: {},
    };
  }

  onChange = (ev) => {
    const { name, value } = ev.target;
    this.setState({ [name]: value });
  };
  onChangeCheckbox = (ev) => {
    const { name, value } = ev.target;
    this.setState({ [name]: addOrRemove(this.state[name], value) });
  };
  onSubmit = (ev) => {
    ev.preventDefault();
    this.setState({ errors: {} });
    const { errors, isValid } = validateForm(this.state);
    if (!isValid) return this.setState({ errors });
    let payload = {
      email: this.state.email,
      appName: this.state.appName,
      description: this.state.description,
      message: this.state.message,
      platform: this.state.platform.join(" & "),
    };
    agent.Common.requestApplication(payload)
      .then((result) => {
        this.setState({
          email: "",
          appName: "",
          description: "",
          errors: {},
          message: result.message,
        });
      })
      .catch((errors) => {
        this.setState({ errors });
      });
  };

  onFinish = values => {
    let payload = { ...values, platform: values.platform.join(' & ') };
    agent.Common.requestApplication(payload)
      .then((result) => {
        message.success("Thank you for submitting your request, we will process this asap.")
      })
      .catch((errors) => {
        message.success("Request failed, Please try in a moment. if error persists drop and email to asif@touchittechnologies.com")
      });
  };

  onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  render() {
    const { email, appName, description, errors, message } = this.state;
    return (
      <Form
        size="large"
        name="appRequestForm"
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
        className="gx-signin-form gx-form-row0"
      >
        <FormItem
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input your e-mail",
            },
          ]}
          name="email"
        >
          <Input placeholder="Email" />
        </FormItem>

        <FormItem
          rules={[{ required: true, message: "Please input application name" }]}
          name="appName"
        >
          <Input placeholder="Application Name" />
        </FormItem>

        <FormItem
          rules={[
            { required: true, message: "Please input application description" },
          ]}
          name="description"
        >
          <TextArea placeholder="Short description about application" />
        </FormItem>

        <FormItem
          rules={[
            {
              required: true,
              message: "Please select TouchIT LED type you have",
            },
          ]}
          initialValue={platformOptions}
          name="platform"
        >
          <CheckboxGroup options={platformOptions} />
        </FormItem>
        <FormItem>
          <Button
            block
            type="primary"
            htmlType="submit"
            size="large"
            className="login-form-button gx-mt-1"
          >
            Send Application Request
          </Button>
        </FormItem>
      </Form>
    );

    return (
      <form className="tg-formtheme tg-formnewsletter" onSubmit={this.onSubmit}>
        <fieldset>
          {message && <div className="alert alert-success">{message}</div>}
          <div className={cx("form-group", { "has-error": errors.email })}>
            <input
              type="email"
              onChange={this.onChange}
              name="email"
              className="form-control"
              placeholder="Your Email Adress"
              value={email}
            />
            <span class="help-block">{errors.email}</span>
          </div>
          <div className={cx("form-group", { "has-error": errors.appName })}>
            <input
              type="text"
              onChange={this.onChange}
              name="appName"
              className="form-control"
              placeholder="Application Name"
              value={appName}
            />
            <span class="help-block">{errors.appName}</span>
          </div>
          <div
            className={cx("form-group", { "has-error": errors.description })}
          >
            <textarea
              onChange={this.onChange}
              name="description"
              className="form-control"
              placeholder="Short description about new application"
              value={description}
            ></textarea>
            <span className="help-block">{errors.description}</span>
          </div>

          <div>
            <div className={"col-xs-6 col-sm-6 col-md-6 col-lg-6"}>
              <div className={"form-group"}>
                <div class="checkbox">
                  <label>
                    <input
                      name="platform"
                      onChange={this.onChangeCheckbox}
                      type="checkbox"
                      value="TouchIT Y Series LED"
                    />
                    TouchIT Y Series LED
                  </label>
                </div>
              </div>
            </div>

            <div className={"col-xs-6 col-sm-6 col-md-6 col-lg-6"}>
              <div className={"form-group"}>
                <div class="checkbox">
                  <label>
                    <input
                      name="platform"
                      onChange={this.onChangeCheckbox}
                      type="checkbox"
                      value="TouchIT Rapide LED"
                    />
                    TouchIT Rapide LED
                  </label>
                </div>
              </div>
            </div>
          </div>
          <button type="submit" className="tg-btn">
            Request Application
          </button>
        </fieldset>
      </form>
    );
  }
}

export default Footer;
