import ListErrors from './ListErrors';
import React, { Fragment } from 'react';
import agent from '../agent';
import { connect } from 'react-redux';

import {
  SETTINGS_SAVED,
  SETTINGS_PAGE_UNLOADED,
  LOGOUT
} from '../constants/actionTypes';

class SettingsForm extends React.Component {
  constructor() {
    super();

    this.state = {
      image: '',
      username: '',
      bio: '',
      email: '',
      password: ''
    };

    this.updateState = field => ev => {
      const state = this.state;
      const newState = Object.assign({}, state, { [field]: ev.target.value });
      this.setState(newState);
    };

    this.submitForm = ev => {
      ev.preventDefault();

      const user = Object.assign({}, this.state);
      if (!user.password) {
        delete user.password;
      }

      this.props.onSubmitForm(user);
    };
  }

  componentWillMount() {
    if (this.props.currentUser) {
      Object.assign(this.state, {
        image: this.props.currentUser.image || '',
        username: this.props.currentUser.username,
        bio: this.props.currentUser.bio,
        email: this.props.currentUser.email
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentUser) {
      this.setState(Object.assign({}, this.state, {
        image: nextProps.currentUser.image || '',
        username: nextProps.currentUser.username,
        bio: nextProps.currentUser.bio,
        email: nextProps.currentUser.email
      }));
    }
  }

  render() {
    return (
      <form className="tg-formtheme tg-formsetting" onSubmit={this.submitForm}>
        <fieldset>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label> URL of profile picture
                  <input
                    className="form-control"
                    type="text"
                    placeholder="URL of profile picture"
                    value={this.state.image}
                    onChange={this.updateState('image')} />
                </label>
              </div>
              <div className="form-group">
                <label>Username
                <input
                    className="form-control"
                    type="text"
                    placeholder="Username"
                    value={this.state.username}
                    onChange={this.updateState('username')} />
                </label>
              </div>
              <div className="form-group">
                <label>Email
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Email"
                    value={this.state.email}
                    onChange={this.updateState('email')} />
                </label>
              </div>
              <div className="form-group">
                <label>New Password
                  <input
                    className="form-control"
                    type="password"
                    placeholder="New Password"
                    value={this.state.password}
                    onChange={this.updateState('password')} />
                </label>
              </div>
              <button
                className="tg-btn"
                onClick={this.props.onClickLogout}>
                Or click here to logout.
              </button>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>Short bio about you
                <textarea
                    className="form-control"
                    placeholder="Short bio about you"
                    value={this.state.bio}
                    onChange={this.updateState('bio')}>
                  </textarea>
                </label>
              </div>
              <button
                className="tg-btn pull-right"
                type="submit"
                disabled={this.state.inProgress}>
                Update Settings
              </button>
            </div>
          </div>
        </fieldset>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  ...state.settings,
  currentUser: state.common.currentUser
});

const mapDispatchToProps = dispatch => ({
  onClickLogout: () => dispatch({ type: LOGOUT }),
  onSubmitForm: user =>
    dispatch({ type: SETTINGS_SAVED, payload: agent.Auth.save(user) }),
  onUnload: () => dispatch({ type: SETTINGS_PAGE_UNLOADED })
});

class Settings extends React.Component {
  render() {
    return (
      <Fragment>

        <h2>Your Settings</h2>

        <ListErrors errors={this.props.errors}></ListErrors>

        <SettingsForm
          currentUser={this.props.currentUser}
          onSubmitForm={this.props.onSubmitForm}
          onClickLogout={this.props.onClickLogout} />

      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
