import ListErrors from './ListErrors';
import React, { Fragment } from 'react';
import Dropzone from 'react-dropzone'
import agent from '../agent';
import { connect } from 'react-redux';
import _ from 'lodash'
import { HOST } from '../config'

import {
  SETTINGS_SAVED,
  SETTINGS_PAGE_UNLOADED,
  LOGOUT
} from '../constants/actionTypes';

function addOrRemove(array, value) {
  var index = array.indexOf(value);
  if (index === -1) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }
  return array;
}


class EditAppForm extends React.Component {
  constructor() {
    super();

    this.state = {
    };

    this.updateState = field => ev => {
      const state = this.state;
      const newState = Object.assign({}, state, { [field]: ev.target.value });
      this.setState(newState);
    };
    this.onSubmit = this.onSubmit.bind(this)
  }

  onChange = (ev) => {
    const { name, value } = ev.target;
    this.setState({ [name]: value })
  }

  onChangeCheckbox = (ev) => {
    const { name, value } = ev.target;
    this.setState({ [name]: addOrRemove(this.state[name], value) })
  }

  async componentDidMount() {
    try {
      let pkgId = this.props.appId
      let appDetails = await agent.Applications.get(pkgId)
      appDetails.app.apkIcon = appDetails.app.icon;
      delete appDetails.app.icon;
      this.setState(appDetails.app)
    } catch (error) {
      console.log(error)
      alert('Invalid application Id. Please contact your Administrator or Tech Support')
      window.location.href = '/'
    }

  }


  componentWillMount() {
    if (this.props.currentUser) {
      Object.assign(this.state, {
        image: this.props.currentUser.image || '',
        username: this.props.currentUser.username,
        bio: this.props.currentUser.bio,
        email: this.props.currentUser.email
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentUser) {
      this.setState(Object.assign({}, this.state, {
        image: nextProps.currentUser.image || '',
        username: nextProps.currentUser.username,
        bio: nextProps.currentUser.bio,
        email: nextProps.currentUser.email
      }));
    }
  }

  async onSubmit(ev) {
    ev.preventDefault();

    try {
      const { title, platform, description, appId, files } = this.state;
      const data = { title, platform, description, appId, files: JSON.stringify(files) };
      const nsgFiles = {};
      if (this.state.apk && this.state.apk.length) {
        nsgFiles.apk = this.state.apk;
      }

      if (this.state.icon && this.state.icon.length) {
        nsgFiles.icon = this.state.icon;
      }

      if (this.state.screenshoots) {
        nsgFiles.screenshoots = this.state.screenshoots;
      }

      if (Object.keys(nsgFiles).length > 0) {
        agent.Applications.updateDetailsAndApk(data, nsgFiles).then((result) => {
          window.location.href = `/app/${this.state.appId}`
        }).catch((err) => {
          alert('Something happen bad while update details. Please contact your Administrator or Tech Support')
          window.location.href = `/app/${this.state.appId}`
        });
      } else {
        agent.Applications.updateDetails(data).then((result) => {
          window.location.href = `/app/${this.state.appId}`
        }).catch((err) => {
          alert('Something happen bad while update details. Please contact your Administrator or Tech Support')
          window.location.href = `/app/${this.state.appId}`
        });
      }
    } catch (error) {
      alert('Something happen bad while update details. Please contact your Administrator or Tech Support')
      window.location.href = `/app/${this.state.appId}`
    }
  }

  onDrop(accepted, rejected, ev) {
    if (accepted.length) {
      this.setState({ [ev.target.name]: accepted })
    }
  }

  removeFile = (file, ev) => {
    ev.preventDefault()
    let { files } = this.state;
    _.remove(files, file);
    this.setState({ files })
  }

  render() {
    const { appId, title, platform, description, files, apkIcon } = this.state;

    return (
      <form className="tg-formtheme tg-formsetting" onSubmit={this.onSubmit}>
        <fieldset>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label> Title
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Application Title"
                    value={title}
                    name="title"
                    onChange={this.onChange} />
                </label>
              </div>

              <div className={'col-xs-12 col-sm-12 col-md-12 col-lg-12'}>
                {/* <label>Supported Platforms</label> */}

                <div className={'col-xs-6 col-sm-6 col-md-6 col-lg-6'}>
                  <div className={'form-group'}>

                    <div class="checkbox">
                      <label>
                        <input
                          onChange={this.onChangeCheckbox}
                          checked={platform ? platform.indexOf('TouchIT Y Series LED') > -1 : false}
                          type="checkbox"
                          value="TouchIT Y Series LED"
                          name="platform"
                        />
                        TouchIT Y Series LED
                  </label>
                    </div>
                  </div>
                </div>

                <div className={'col-xs-6 col-sm-6 col-md-6 col-lg-6'}>
                  <div className={'form-group'}>
                    <div class="checkbox">
                      <label>
                        <input
                          onChange={this.onChangeCheckbox}
                          checked={platform ? platform.indexOf('TouchIT Rapide LED') > -1 : false}
                          type="checkbox"
                          value="TouchIT Rapide LED"
                          name="platform"
                        />
                        TouchIT Rapide LED
                    </label>
                    </div>
                  </div>
                </div>

                <div className="clearfix"></div>
                <div className={'col-xs-12 col-sm-4 col-md-4 col-lg-4'}>
                  <div className="form-group">
                    <label>Select Apk
                  <Dropzone
                        multiple={false}
                        accept="application/vnd.android.package-archive"
                        onDrop={this.onDrop.bind(this)}
                        inputProps={{ name: 'apk', style: { 'opacity': 1, 'position': 'relative' } }}
                        className="nsg-fileinput"
                      />
                    </label>
                  </div>
                </div>

              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div className="form-group">
                <label>Description
                <textarea
                    className="form-control"
                    placeholder="description"
                    value={description}
                    onChange={this.updateState('description')}>
                  </textarea>
                </label>
              </div>
              <button
                className="tg-btn pull-right"
                type="submit"
                disabled={this.state.inProgress}>
                Update Application Details
              </button>
            </div>
          </div>

          <div className={'col-xs-4 col-sm-4 col-md-4 col-lg-4'}>
            <div className="form-group">
              <label>Select Icon
                  <Dropzone
                  multiple={false}
                  accept="image/*"
                  onDrop={this.onDrop.bind(this)}
                  inputProps={{ name: 'icon', style: { 'opacity': 1, 'position': 'relative' } }}
                  className="nsg-fileinput"
                />
              </label>
              {apkIcon && <div className="nsg-filepreview-container"> <img src={`${HOST}/apps/${appId}/${apkIcon}`} /></div>}
            </div>
          </div>

          <div className={'col-xs-8 col-sm-8 col-md-8 col-lg-8'}>
            <div className="form-group">
              <label>Select Screenshoots
                  <Dropzone
                  multiple={true}
                  accept="image/*"
                  onDrop={this.onDrop.bind(this)}
                  inputProps={{ name: 'screenshoots', style: { 'opacity': 1, 'position': 'relative' } }}
                  className="nsg-fileinput"
                />
              </label>
            </div>
            {files && files.length ? <div className="nsg-filepreview-container">
              {files.map(file => <div className={'col-xs-4 col-sm-4 col-md-4 col-lg-4'} style={{ position: 'relative' }}>
                <button onClick={this.removeFile.bind(this, file)} style={{
                  position: 'absolute',
                  top: -10,
                  left: 0,
                  background: 'red',
                  color: 'white',
                  fontSize: 30,
                  borderRadius: '50%',
                  padding: 5,
                  cursor: 'pointer'
                }}>x</button>
                <img src={`${HOST}/${file.path}`} />
              </div>)};
                        </div> : null}
          </div>


        </fieldset>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  ...state.settings,
  currentUser: state.common.currentUser
});

const mapDispatchToProps = dispatch => ({
  onClickLogout: () => dispatch({ type: LOGOUT }),
  onSubmitForm: user =>
    dispatch({ type: SETTINGS_SAVED, payload: agent.Auth.save(user) }),
  onUnload: () => dispatch({ type: SETTINGS_PAGE_UNLOADED })
});

class EditApp extends React.Component {
  render() {
    return (
      <Fragment>

        <h2>Edit Application Detail</h2>

        <ListErrors errors={this.props.errors}></ListErrors>

        <EditAppForm
          currentUser={this.props.currentUser}
          onSubmitForm={this.props.onSubmitForm}
          appId={this.props.match.params.id}
        />

      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditApp);
