import {
  GOOGLE_APP_PAGE_LOADED,
  GOOGLE_APP_PAGE_UNLOADED,
  APP_SUBMITTED,
  ASYNC_START,
  ADD_TAG,
  REMOVE_TAG,
  UPDATE_FIELD_GOOGLE_APP,
  UPDATE_FIELD_GOOGLE_APP_CHECKBOX
} from '../constants/actionTypes';

function addOrRemove(array, value) {
  var index = array.indexOf(value);
  if (index === -1) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }
  return array;
}

export default (state = {}, action) => {
  switch (action.type) {
    case GOOGLE_APP_PAGE_LOADED:
      return {
        ...state,
        appSlug: action.payload ? action.payload.app.slug : '',
        appId: action.payload ? action.payload.app.appId : '',
        tagInput: '',
        tagList: action.payload ? action.payload.app.tagList : []
      };
    case GOOGLE_APP_PAGE_UNLOADED:
      return {};
    case APP_SUBMITTED:
      return {
        ...state,
        inProgress: null,
        errors: action.error ? action.payload.errors : null
      };
    case ASYNC_START:
      if (action.subtype === APP_SUBMITTED) {
        return { ...state, inProgress: true };
      }
      break;
    case ADD_TAG:
      return {
        ...state,
        tagList: [...new Set(state.tagList.concat([state.tagInput]))],
        tagInput: ''
      };
    case REMOVE_TAG:
      return {
        ...state,
        tagList: state.tagList.filter(tag => tag !== action.tag)
      };
    case UPDATE_FIELD_GOOGLE_APP:
      return { ...state, [action.key]: action.value };
    case UPDATE_FIELD_GOOGLE_APP_CHECKBOX:
      if (!state[action.key]) { state[action.key] = [] };
      return { ...state, [action.key]: addOrRemove(state[action.key], action.value.value) };
    default:
      return state;
  }

  return state;
};
