import { USERS_LIST_PAGE_LOAD, USERS_LIST_PAGE_UNLOAD, SET_USER_PAGE } from "../constants/actionTypes";

export default (state = {}, action) => {
    switch (action.type) {
        case USERS_LIST_PAGE_LOAD:
            return {
                ...state,
                usersList: action.payload[0].usersList,
                usersCount: action.payload[0].usersCount
            }
        case SET_USER_PAGE:
            return {
                ...state,
                usersList: action.payload.usersList,
                usersCount: action.payload.usersCount,
                currentPage: action.page
            };
        case USERS_LIST_PAGE_UNLOAD:
            return {}
        default:
            return state;
    }

}