import React from 'react';
import { Button, Tag } from "antd";
import StarRatingComponent from "react-star-rating-component";
import { Link } from 'react-router-dom';
import map from 'lodash/map';
import agent from '../agent';
import { connect } from 'react-redux';
import marked from 'marked';
import { APP_FAVORITED, APP_UNFAVORITED } from '../constants/actionTypes';
import { HOST } from '../config'

const FAVORITED_CLASS = 'nsg-fav';
const NOT_FAVORITED_CLASS = '';

const mapDispatchToProps = dispatch => ({
  favorite: appId => dispatch({
    type: APP_FAVORITED,
    payload: agent.Applications.favorite(appId)
  }),
  unfavorite: appId => dispatch({
    type: APP_UNFAVORITED,
    payload: agent.Applications.unfavorite(appId)
  })
});

const AppPreview = props => {
  const app = props.app;
  const favoriteButtonClass = app.favorited ?
    FAVORITED_CLASS :
    NOT_FAVORITED_CLASS;

  const handleClick = ev => {
    ev.preventDefault();
    if (app.favorited) {
      props.unfavorite(app.appId);
    } else {
      props.favorite(app.appId);
    }
  };

  const summaryMarkup = { __html: marked(app.summary, { sanitize: true }) };
  return (<div className={`gx-product-item gx-product-vertical`}>
    <Link to={`/app/${app.appId}`} className="gx-product-image">
      <div className="gx-grid-thumb-equal">
        <span className="gx-link gx-grid-thumb-cover">
          <img alt="Remy Sharp" src={`${HOST}/apps/${app.appId}/${app.icon}`} onError={e => {
            e.target.src = `/images/TouchIT_App_Store_Icon_v2.png`;
          }} alt={app.title} />
        </span>
      </div>
    </Link>

    <Link to={`/app/${app.appId}`} className="gx-product-body">
      <h3 className="gx-product-title">
        {app.title}
      </h3>

      <div className="ant-row-flex">
        <h4>{map(app.tagList, (tag) => <Tag color="red">{tag}</Tag>)} </h4>
      </div>


      <div className="ant-row-flex gx-mb-1">
        <StarRatingComponent
          name=""
          value={5}
          starCount={(Math.round(app.score * 100) / 100) || 3.5}
          editing={false} />
        <strong className="gx-d-inline-block gx-ml-2">{(Math.round(app.score * 100) / 100) || 3.5}</strong>
      </div>
      <p dangerouslySetInnerHTML={summaryMarkup}></p>
    </Link>

    <div className="gx-product-footer">
      {props.currentUser ?
        <Button block type={app.favorited ? 'primary' : ''} onClick={handleClick}>{app.favorited ? 'Remove from Whishlist' : 'Add to Wishlist'}</Button>
        :
        <Button block href={`/login`}>Add to Wishlist </Button>
      }
      <Button block type="primary" href={`/app/${app.appId}`}>Read More</Button>
    </div>
  </div>)


  return (<article className="tg-app">
    <div className="tg-appholder">
      <figure className="tg-appimg">
        <Link to={`/app/${app.appId}`}><img src={`${HOST}/apps/${app.appId}/${app.icon}`} onError={e => {
          e.target.src = `/images/TouchIT_App_Store_Icon_v2.png`;
        }} alt={app.title} /></Link>
      </figure>
      <div className="tg-appcontent">
        <span className="tg-apptag">{app.tagList.join(' | ')}</span>
        <span className="tg-apprating">
          <i className="fa fa-star"></i>
          <i>{Math.round(app.score * 100) / 100}</i>
        </span>
        <div className="tg-apptitle">
          <h3><Link to={`/app/${app.appId}`}>{app.title}</Link></h3>
        </div>
        <div className="tg-description">
          <div dangerouslySetInnerHTML={summaryMarkup}></div>
          <Link to={`/app/${app.appId}`}><span>Read more...</span></Link>
        </div>
        {props.currentUser ?
          <a className={`${favoriteButtonClass} tg-btnaddtowishlist`} onClick={handleClick}>{app.favorited ? 'Remove from Whishlist' : 'Add to Wishlist'}</a>
          :
          <Link className={`${favoriteButtonClass} tg-btnaddtowishlist`} to={`/login`}>Add to Wishlist </Link>
        }
      </div>
    </div>
  </article>
  );
}

export default connect(() => ({}), mapDispatchToProps)(AppPreview);




// import React from 'react';
// import { Link } from 'react-router-dom';
// import agent from '../agent';
// import { connect } from 'react-redux';
// import { APP_FAVORITED, APP_UNFAVORITED } from '../constants/actionTypes';

// const FAVORITED_CLASS = 'btn btn-sm btn-primary';
// const NOT_FAVORITED_CLASS = 'btn btn-sm btn-outline-primary';

// const mapDispatchToProps = dispatch => ({
//   favorite: appId => dispatch({
//     type: APP_FAVORITED,
//     payload: agent.Applications.favorite(appId)
//   }),
//   unfavorite: appId => dispatch({
//     type: APP_UNFAVORITED,
//     payload: agent.Applications.unfavorite(appId)
//   })
// });

// const AppPreview = props => {
//   const app = props.app;
//   const favoriteButtonClass = app.favorited ?
//     FAVORITED_CLASS :
//     NOT_FAVORITED_CLASS;

//   const handleClick = ev => {
//     ev.preventDefault();
//     if (app.favorited) {
//       props.unfavorite(app.appId);
//     } else {
//       props.favorite(app.appId);
//     }
//   };

//   return (
//     <div className="article-preview">
//       <div className="article-meta">
//         {/* <Link to={`/@${app.author.username}`}>
//           <img src={app.author.image} alt={app.author.username} />
//         </Link> */}

//         <div className="info">
//           {/* <Link className="author" to={`/@${app.author.username}`}>
//             {app.author.username}
//           </Link> */}
//           <span className="date">
//             {new Date(app.createdAt).toDateString()}
//           </span>
//         </div>

//         <div className="pull-xs-right">
//           <button className={favoriteButtonClass} onClick={handleClick}>
//             <i className="ion-heart"></i> {app.favoritesCount}
//           </button>
//         </div>
//       </div>

//       <Link to={`/app/${app.appId}`} className="preview-link">
//         <h1>{app.title}</h1>
//         <p>{app.description}</p>
//         <span>Read more...</span>
//         <ul className="tag-list">
//           {
//             app.tagList.map(tag => {
//               return (
//                 <li className="tag-default tag-pill tag-outline" key={tag}>
//                   {tag}
//                 </li>
//               )
//             })
//           }
//         </ul>
//       </Link>
//     </div>
//   );
// }

// export default connect(() => ({}), mapDispatchToProps)(AppPreview);
