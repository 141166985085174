import React from 'react';
import { Link } from 'react-router-dom'
import agent from '../../agent';
import { connect } from 'react-redux';
import { APP_PAGE_FAVORITED, APP_PAGE_UNFAVORITED } from '../../constants/actionTypes';

const FAVORITED_CLASS = 'btn btn-sm btn-primary';
const NOT_FAVORITED_CLASS = 'btn btn-sm btn-outline-primary';


const mapDispatchToProps = dispatch => ({
  favorite: appId => dispatch({
    type: APP_PAGE_FAVORITED,
    payload: agent.Applications.favorite(appId)
  }),
  unfavorite: appId => dispatch({
    type: APP_PAGE_UNFAVORITED,
    payload: agent.Applications.unfavorite(appId)
  })
});


const FavoriteButton = props => {
  const app = props.app;
  const favoriteButtonClass = app.favorited ?
    FAVORITED_CLASS :
    NOT_FAVORITED_CLASS;

  const handleClick = ev => {
    ev.preventDefault();
    if (app.favorited) {
      props.unfavorite(app.appId);
    } else {
      props.favorite(app.appId);
    }
  };

  if (props.currentUser) {
    return <a onClick={handleClick} className={`${favoriteButtonClass} tg-btnaddtowishlist`} >
      <i className="fa fa-star" />
      <span>{app.favorited ? 'Remove from whishlist' : 'Add to whishlist'}</span>
    </a>
  } else {
    return <Link to={`/login`} className={`${favoriteButtonClass} tg-btnaddtowishlist`}>
      <i className="fa fa-star" />
      <span>Add to Wishlist</span>
    </Link>
  }
};

export default connect(() => ({}), mapDispatchToProps)(FavoriteButton);