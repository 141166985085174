import React from 'react';
import agent from '../../agent';
import { connect } from 'react-redux';
import { ADD_COMMENT } from '../../constants/actionTypes';

const mapDispatchToProps = dispatch => ({
  onSubmit: payload =>
    dispatch({ type: ADD_COMMENT, payload })
});

class CommentInput extends React.Component {
  constructor() {
    super();
    this.state = {
      body: ''
    };

    this.setBody = ev => {
      this.setState({ body: ev.target.value });
    };

    this.createComment = ev => {
      ev.preventDefault();
      const payload = agent.Comments.create(this.props.slug,
        { body: this.state.body });
      this.setState({ body: '' });
      this.props.onSubmit(payload);
    };
  }

  render() {
    return (
      <form onSubmit={this.createComment}>
        <textarea className="form-control"
          placeholder="Write a comment..."
          value={this.state.body}
          onChange={this.setBody}
          rows="3">
        </textarea>


        <div className="nsg-floatright">

          {/* <img
            src={`https://dummyimage.com/40x40/3876b4/ffffff.png&text= ${this.props.currentUser.username}`}
            className="nsg-dppreview"
            alt={this.props.currentUser.username} /> */}
          <button
            className="btn btn-sm btn-primary"
            type="submit">
            Post Comment
          </button>
        </div>
      </form>
    );
  }
}

export default connect(() => ({}), mapDispatchToProps)(CommentInput);
