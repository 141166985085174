import {
  APP_PAGE_LOADED,
  APP_PAGE_UNLOADED,
  ADD_COMMENT,
  DELETE_COMMENT,
  APP_PAGE_FAVORITED,
  APP_PAGE_UNFAVORITED
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case APP_PAGE_FAVORITED:
    case APP_PAGE_UNFAVORITED:
      return {
        ...state,
        app: action.payload.app,
      };

    case APP_PAGE_LOADED:
      return {
        ...state,
        app: action.payload[0].app,
        suggestedApps: action.payload[0].suggestedApps,
        comments: action.payload[1].comments
      };
    case APP_PAGE_UNLOADED:
      return {};
    case ADD_COMMENT:
      return {
        ...state,
        commentErrors: action.error ? action.payload.errors : null,
        comments: action.error ?
          null :
          (state.comments || []).concat([action.payload.comment])
      };
    case DELETE_COMMENT:
      const commentId = action.commentId
      return {
        ...state,
        comments: state.comments.filter(comment => comment.id !== commentId)
      };
    default:
      return state;
  }
};
