import ListErrors from './ListErrors';
import React, { Fragment } from 'react';
import agent from '../agent';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone'
import TextFieldGroup from './common/TextFieldGroup'
import validateInput from '../validations/myApp'
import {
  MY_APP_ADD_TAG,
  MY_APP_PAGE_LOADED,
  MY_APP_REMOVE_TAG,
  MY_APP_SUBMITTED,
  MY_APP_PAGE_UNLOADED,
  UPDATE_FIELD_MY_APP,
  UPDATE_FIELD_GOOGLE_APP_CHECKBOX
} from '../constants/actionTypes';

const mapStateToProps = state => ({
  ...state.myApp
});

const mapDispatchToProps = dispatch => ({
  onAddTag: () =>
    dispatch({ type: MY_APP_ADD_TAG }),
  onLoad: payload =>
    dispatch({ type: MY_APP_PAGE_LOADED, payload }),
  onRemoveTag: tag =>
    dispatch({ type: MY_APP_REMOVE_TAG, tag }),
  onSubmit: payload =>
    dispatch({ type: MY_APP_SUBMITTED, payload }),
  onUnload: payload =>
    dispatch({ type: MY_APP_PAGE_UNLOADED }),
  onUpdateField: (key, value) =>
    dispatch({ type: UPDATE_FIELD_MY_APP, key, value }),
  onUpdateCheckboxField: (key, value) =>
    dispatch({ type: UPDATE_FIELD_GOOGLE_APP_CHECKBOX, key, value })
});

class PackageUpload extends React.Component {
  constructor() {
    super();

    this.state = { errors: {} }

    this.watchForEnter = ev => {
      if (ev.keyCode === 13) {
        ev.preventDefault();
        this.props.onAddTag();
      }
    };

    this.removeTagHandler = tag => () => {
      this.props.onRemoveTag(tag);
    };

    this.submitForm = ev => {
      ev.preventDefault();

      if (this.isValid()) {
        this.setState({ errors: {}, isLoading: true });

        const { appSlug, appId, title, summary, description, icon, screenshoots, apk, video, price, tagList, platform } = this.props;

        let data = { appSlug, appId, title, summary, description, video, price, tagList, platform }
        let files = { icon: [icon[0]], apk: [apk[0]], screenshoots: screenshoots }

        const myApp = {};

        const slug = { slug: this.props.appSlug };
        const promise = this.props.appSlug ?
          agent.Applications.updateMyApp(Object.assign(myApp, slug)) :
          agent.Applications.createMyApp(data, files);

        this.props.onSubmit(promise);
      }



    };
  }

  isValid = () => {
    const { errors, isValid } = validateInput(this.props);

    if (!isValid) {
      this.setState({ errors });
    }

    return isValid;
  }


  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.slug !== nextProps.match.params.slug) {
      if (nextProps.match.params.slug) {
        this.props.onUnload();
        return this.props.onLoad(agent.Applications.get(this.props.match.params.slug));
      }
      this.props.onLoad(null);
    }
  }

  componentWillMount() {
    if (this.props.match.params.slug) {
      return this.props.onLoad(agent.Applications.get(this.props.match.params.slug));
    }
    this.props.onLoad(null);
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  onChange = (ev) => {
    this.props.onUpdateField([ev.target.name], ev.target.value);
  }

  onChangeCheckbox = (ev) => {
    this.props.onUpdateCheckboxField([ev.target.name], ev.target);
  }

  onFileSelect = (ev) => {
    this.props.onUpdateField([ev.target.name], ev.target.files);
  }

  onDrop(accepted, rejected, ev) {
    // let files = accepted.map(file => ({
    //   ...file,
    //   preview: URL.createObjectURL(file)
    // }))

    // debugger

    this.props.onUpdateField([ev.target.name], accepted);

  }

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }




  render() {
    // const { errors, appId, title, summary, icon, screenshoots, apk, video, description, price, isLoading, invalid } = this.state
    return (
      <Fragment>
        <div className="tg-sectiontitle at-paddonlybottom">
          <h2><span>Add New Application</span></h2>
        </div>
        <ListErrors errors={this.props.errors || this.state.errors}></ListErrors>
        <form className={'tg-formtheme tg-formpackageupload'}>
          <fieldset>
            <div className={'row'}>
              <div className={'col-xs-12 col-sm-6 col-md-6 col-lg-6'}>
                <div className={'form-group'}>
                  <TextFieldGroup
                    label="Package Id"
                    onChange={this.onChange}
                    value={this.props.appId}
                    field="appId"
                    type="text"
                  />
                </div>
                <div className={'form-group'}>
                  <TextFieldGroup
                    label="Package Name"
                    onChange={this.onChange}
                    value={this.props.title}
                    field="title"
                    type="text"
                  />
                </div>
                <div className={'form-group'}>
                  <TextFieldGroup
                    label="Summary"
                    onChange={this.onChange}
                    value={this.props.summary}
                    field="summary"
                    type="text"
                  />
                </div>
                <div className="form-group">
                  {/* <label>Tags</label> */}
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter tags"
                    value={this.props.tagInput}
                    name="tagInput"
                    onChange={this.onChange}
                    onKeyUp={this.watchForEnter} />

                  <div className="nsg-taglist">
                    {
                      (this.props.tagList || []).map(tag => {
                        return (
                          <span className="nsg-tag" key={tag}>
                            {tag}

                            <i className="fa fa-times-circle-o"
                              onClick={this.removeTagHandler(tag)}>
                            </i>
                          </span>
                        );
                      })
                    }
                  </div>
                </div>
              </div>
              <div className={'col-xs-12 col-sm-6 col-md-6 col-lg-6'}>
                <div className="form-group">
                  {/* <label> Description</label> */}
                  <textarea
                    className="form-control"
                    name="description"
                    rows="8"
                    placeholder="Write your description (in markdown)"
                    value={this.props.description}
                    onChange={this.onChange}>
                  </textarea>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className={'col-xs-12 col-sm-4 col-md-4 col-lg-4'}>
                <div className="form-group">
                  <label>Select Apk
                  <Dropzone
                      multiple={false}
                      accept="application/vnd.android.package-archive"
                      onDrop={this.onDrop.bind(this)}
                      inputProps={{ name: 'apk', style: { 'opacity': 1, 'position': 'relative' } }}
                      className="nsg-fileinput"
                    />
                  </label>
                </div>
              </div>
              <div className={'col-xs-12 col-sm-4 col-md-4 col-lg-4'}>
                <div className="form-group">
                  <label>Select Icon
                  <Dropzone
                      multiple={false}
                      accept="image/*"
                      onDrop={this.onDrop.bind(this)}
                      inputProps={{ name: 'icon', style: { 'opacity': 1, 'position': 'relative' } }}
                      className="nsg-fileinput"
                    />
                  </label>
                  {/* {this.props.icon && this.props.icon.length ? <div className="nsg-filepreview-container"> <img src={this.props.icon[0].preview} /></div> : null} */}
                </div>
              </div>
              <div className={'col-xs-12 col-sm-4 col-md-4 col-lg-4'}>
                <div className="form-group">
                  <label>Select Screenshoots
                  <Dropzone
                      multiple={true}
                      accept="image/*"
                      onDrop={this.onDrop.bind(this)}
                      inputProps={{ name: 'screenshoots', style: { 'opacity': 1, 'position': 'relative' } }}
                      className="nsg-fileinput"
                    />
                  </label>
                </div>
                {/* {this.props.screenshoots && this.props.screenshoots.length ? <div className="nsg-filepreview-container">
                          {this.props.screenshoots.map(file => <img src={file.preview} />)};
                        </div> : null} */}
              </div>

              <div className={'col-xs-12 col-sm-12 col-md-12 col-lg-12'}>

                <div className={'col-xs-6 col-sm-6 col-md-6 col-lg-6'}>
                  <div className={'form-group'}>

                    <div class="checkbox">
                      <label>
                        <input
                          name="platform"
                          onChange={this.onChangeCheckbox}
                          type="checkbox"
                          value="TouchIT Y Series LED"
                        />
                        TouchIT Y Series LED
                  </label>
                    </div>
                  </div>
                </div>

                <div className={'col-xs-6 col-sm-6 col-md-6 col-lg-6'}>
                  <div className={'form-group'}>
                    <div class="checkbox">
                      <label>
                        <input
                          name="platform"
                          onChange={this.onChangeCheckbox}
                          type="checkbox"
                          value="TouchIT Rapide LED"
                        />
                        TouchIT Rapide LED
                    </label>
                    </div>
                  </div>
                </div>
              </div>


              <div className="form-group">
                <button
                  className="tg-btn pull-right"
                  type="button"
                  disabled={this.props.inProgress}
                  onClick={this.submitForm}>
                  Publish Application
                    </button>
              </div>
            </div>
          </fieldset>
        </form>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PackageUpload);
