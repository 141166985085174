import {
  MY_APP_PAGE_LOADED,
  MY_APP_PAGE_UNLOADED,
  MY_APP_SUBMITTED,
  MY_APP_ASYNC_START,
  MY_APP_ADD_TAG,
  MY_APP_REMOVE_TAG,
  UPDATE_FIELD_MY_APP,
  UPDATE_FIELD_GOOGLE_APP_CHECKBOX
} from '../constants/actionTypes';


function addOrRemove(array, value) {
  var index = array.indexOf(value);
  if (index === -1) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }
  return array;
}


export default (state = {}, action) => {
  switch (action.type) {
    case MY_APP_PAGE_LOADED:
      return {
        ...state,
        appSlug: action.payload ? action.payload.app.slug : '',
        appId: action.payload ? action.payload.app.appId : '',
        title: action.payload ? action.payload.app.title : '',
        summary: action.payload ? action.payload.app.summary : '',
        description: action.payload ? action.payload.app.description : '',
        icon: action.payload ? action.payload.app.icon : [],
        screenshoots: action.payload ? action.payload.app.screenshoots : [],
        apk: action.payload ? action.payload.app.apk : [],
        video: action.payload ? action.payload.app.video : [],
        price: action.payload ? action.payload.app.price : '',
        tagInput: '',
        tagList: action.payload ? action.payload.app.tagList : []
      };
    case MY_APP_PAGE_UNLOADED:
      return {};
    case MY_APP_SUBMITTED:
      return {
        ...state,
        inProgress: null,
        errors: action.error ? action.payload.errors : null
      };
    case MY_APP_ASYNC_START:
      if (action.subtype === MY_APP_SUBMITTED) {
        return { ...state, inProgress: true };
      }
      break;
    case MY_APP_ADD_TAG:
      return {
        ...state,
        tagList: [...new Set(state.tagList.concat([state.tagInput]))],
        tagInput: ''
      };
    case MY_APP_REMOVE_TAG:
      return {
        ...state,
        tagList: state.tagList.filter(tag => tag !== action.tag)
      };
    case UPDATE_FIELD_MY_APP:
      return { ...state, [action.key]: action.value };
    case UPDATE_FIELD_GOOGLE_APP_CHECKBOX:
      if (!state[action.key]) { state[action.key] = [] };
      debugger
      return { ...state, [action.key]: addOrRemove(state[action.key], action.value.value) };
    default:
      return state;
  }

  return state;
};
