import React from 'react';
class HomePage extends React.Component {
  // constructor(props) {
  //   super(props)
  // }
  componentDidMount() {
    window.jQuery(this.hs).pogoSlider({
      autoplay: false,
      responsive: true,
      targetHeight: 445,
      generateNav: false,
      pauseOnHover: false,
      displayProgess: true,
      autoplayTimeout: 6000,
    }).data('plugin_pogoSlider');
  }

  render() {
    return (<div className="tg-home">
      <div ref={hs => this.hs = hs} id="tg-homeslider" className="tg-homeslider tg-haslayout">
        <div className="pogoSlider-slide" data-transition="fade" data-duration="1500" style={{ "background": "url(images/banner/img-01.jpg) no-repeat scroll 0 0" }}>
          <div className="tg-slidecontent">
            <h1 className="pogoSlider-slide-element" data-in="slideDown" data-out="slideUp" data-duration="1800" data-delay="500">{this.props.appName}</h1>
            <div className="tg-description pogoSlider-slide-element" data-in="slideLeft" data-out="slideRight" data-duration="1800" data-delay="500">
              <p>The place to download free content for your TouchIT LED.</p>
            </div>
            <a target="_blank" rel="noopener noreferrer" className="tg-btn pogoSlider-slide-element" data-in="slideUp" data-out="slideDown" data-duration="1800" data-delay="500" href={'http://api.touchitappstore.com/TouchIT_App_Store_v3.apk'}>Download App Store</a>
          </div>
        </div>
      </div>
    </div>);
  };
}

export default HomePage;