import { Card, Row, Col } from 'antd'
import CommentContainer from './CommentContainer';
import FavoriteButton from './FavoriteButton';
import Auxiliary from '../../utils/Auxiliary'
import { Link } from 'react-router-dom';
import React, { Fragment } from 'react';
import agent from '../../agent';
import { connect } from 'react-redux';
import marked from 'marked';
import { APP_PAGE_LOADED, APP_PAGE_UNLOADED, DELETE_APP, CHANGE_TAB } from '../../constants/actionTypes';
import { HOST } from '../../config'
import AppHeader from './AppHeader';

const mapStateToProps = state => ({
  ...state.app,
  currentUser: state.common.currentUser
});

const mapDispatchToProps = dispatch => ({
  onLoad: payload =>
    dispatch({ type: APP_PAGE_LOADED, payload }),
  onUnload: () =>
    dispatch({ type: APP_PAGE_UNLOADED }),
  onClickDelete: payload =>
    dispatch({ type: DELETE_APP, payload }),
  onClickUpdateGoogleApp: payload =>
    dispatch({ type: DELETE_APP, payload }),
  onTabClick: (tab, pager, payload) => dispatch({ type: CHANGE_TAB, tab, pager, payload }),
});

class AppComponent extends React.Component {
  componentWillMount() {
    this.props.onLoad(Promise.all([
      agent.Applications.get(this.props.match.params.id),
      agent.Comments.forArticle(this.props.match.params.id),
      this.props.onTabClick('search', agent.Applications['all'], agent.Applications['all']())
    ]));
  }

  componentWillUnmount() {
    this.props.onUnload();
  }


  del = () => {
    this.props.onClickDelete(agent.Applications.del(this.props.app.appId))
  };

  updateGoogleApp = () => {
    this.props.onClickUpdateGoogleApp(agent.Applications.updateGoogleApp(this.props.app.appId))
  };

  render() {
    if (!this.props.app) {
      return null;
    }

    console.log(this.props.app)
    const { title, appId, icon, description, score, summary, androidVersionText } = this.props.app;
    const suggestedApps = this.props.suggestedApps;

    const descriptionMarkup = { __html: marked(description, { sanitize: true }) };
    const summaryMarkup = { __html: marked(summary, { sanitize: true }) };

    // const canModify = this.props.currentUser &&
    //   this.props.currentUser.username === this.props.app.author.username;

    let mySuggestedApps = []

    suggestedApps.forEach((myApp) => {
      mySuggestedApps.push(
        <Card className="gx-card">
            <figure className="tg-appimg"><a href={`/app/${myApp.appId}`}><img src={`${HOST}/apps/${myApp.appId}/${myApp.icon}`} alt="image description" /></a></figure>
            <div className="tg-appcontent">
              <span className="tg-apptag">{myApp.tagList.join(' | ')}</span>
              <span className="tg-apprating">
                <i className="fa fa-star" />
                <i>{Math.round(myApp.score * 10) / 10}</i>
              </span>
              <div className="tg-apptitle">
                <h3><a href="javascript:void(0);">{myApp.title}</a></h3>
              </div>
              <div className="tg-description" dangerouslySetInnerHTML={{ __html: marked(myApp.summary, { sanitize: true }) }}>
              </div>
            </div>
        </Card>
      )
    });

    // return (<Auxiliary>
    //     <AppHeader />
    //     <div className="gx-profile-content">
    //       <Row>
    //         <Col xl={16} lg={14} md={14} sm={24} xs={24}>
    //           {/* <About />
    //         <Biography />
    //         <Events /> */}
    //         </Col>

    //         <Col xl={8} lg={10} md={10} sm={24} xs={24}>
    //           {/* <Contact /> */}
    //           <Row>
    //             <Col xl={24} lg={24} md={24} sm={12} xs={24}>
    //               {/* <Friends friendList={friendList} /> */}
    //             </Col>
    //             <Col xl={24} lg={24} md={24} sm={12} xs={24}>
    //               {/* <Photos photoList={photoList} /> */}
    //             </Col>
    //           </Row>
    //         </Col>
    //       </Row>
    //     </div>
    // </Auxiliary>)

    return (<main id="tg-main" className="tg-main tg-bglight tg-appdetailmain tg-haslayout">
      <div className="container-fluid">
        <div className="row">
          <div id="tg-twocolumns" className="tg-twocolumns">
            <div id="tg-content" className="tg-content">
              <Row>
                <Col span={18}>

                  <div className="tg-appdetail">
                    <div className="tg-apphead">

                      <figure><img className="nsg-iconstyle" src={`${HOST}/apps/${appId}/${icon}`} alt="image description" /></figure>

                      <div className="tg-appheadcontent">
                        <div className="tg-apptitle">
                          <h2>{title}</h2>
                          {/* <span className="tg-like tg-liked"><i className="fa fa-heart" /></span> */}
                        </div>
                        <div className="tg-appratingandcounts">
                          <span className="tg-appstarrating">
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                            <i className="fa fa-star" />
                          </span>
                          <span>{Math.round(score * 100) / 100}</span>
                        </div>
                        <div className="tg-shortdetail">
                          <p dangerouslySetInnerHTML={summaryMarkup}></p>
                          {/* <p>This app is compatible with all of your devices.</p> */}
                        </div>
                        <div className="tg-wishlistanddownloadbtn">

                          <FavoriteButton app={this.props.app} currentUser={this.props.currentUser} />

                          {this.props.currentUser ?
                            <a className="tg-btn" href={`${HOST}/apps/${appId}/${appId}.apk`}>download app</a>
                            :
                            <Link className="tg-btn" to={'/login'}>download app</Link>
                          }
                          {
                            this.props.currentUser && this.props.currentUser.role == 'admin' ?
                              <Fragment>
                                <a onClick={this.del} className="tg-btn" href={null}>
                                  <i className="fa fa-trash-o"></i>
                                &nbsp;
                                Delete Application
                              </a>


                                {androidVersionText ? <a onClick={this.updateGoogleApp} className="tg-btn" href={null}>
                                  <i className="fa fa-refresh"></i>
                                &nbsp;
                                Update Application
                              </a> : null}

                                <Link to={`/edit/${appId}`} className="tg-btn" href={null}>
                                  <i className="fa fa-pencil"></i>
                                &nbsp;
                                Edit Application Details
                              </Link>

                              </Fragment>
                              : null
                          }
                        </div>
                      </div>
                    </div>
                    <div className="tg-appbody">
                      <div className="tg-appimggallery tg-horizontalthemescrollbar">
                        <ul>

                          {
                            this.props.app.files.map((file, key) => {
                              return (
                                <li key={key}><figure><img src={`${HOST}/apps/${appId}/${file.title}`} alt="image description" /></figure></li>
                              );
                            })
                          }
                        </ul>
                      </div>



                      <h3><strong>APP DESCRIPTION</strong></h3>

                      <div dangerouslySetInnerHTML={descriptionMarkup}></div>

                      <div className="tg-appdetaildescription"></div>
                      <div className="tg-appreviewsarea">
                        <div className="tg-appreviewshead">
                          <h4>Reviews</h4>
                          <span>{this.props.comments ? this.props.comments.length : 0}</span>
                        </div>
                        <ul className="tg-appreviews">

                          <CommentContainer
                            comments={this.props.comments || []}
                            errors={this.props.commentErrors}
                            slug={this.props.match.params.id}
                            currentUser={this.props.currentUser} />

                        </ul>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col span={6}>
                  <Card className="gx-card">
                    {mySuggestedApps}
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </main>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppComponent);