import React from 'react';
import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames'

import agent from '../../agent'
// import { Link } from 'react-router-dom';

// const Footer = () => {
function validateForm(data) {
  let errors = {};

  if (Validator.isEmpty(data.email)) {
    errors.email = 'This field is required';
  }
  if (!errors.email && !Validator.isEmail(data.email)) {
    errors.email = 'Email is invalid';
  }

  if (Validator.isEmpty(data.appName)) {
    errors.appName = 'This field is required';
  }
  if (Validator.isEmpty(data.description)) {
    errors.description = 'This field is required';
  }


  return {
    errors,
    isValid: isEmpty(errors)
  }

}

function addOrRemove(array, value) {
  var index = array.indexOf(value);
  if (index === -1) {
    array.push(value);
  } else {
    array.splice(index, 1);
  }
  return array;
}

class Footer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      appName: '',
      description: '',
      message: '',
      platform: [],
      errors: {},
    }

  }

  onChange = (ev) => {
    const { name, value } = ev.target;
    this.setState({ [name]: value })
  }
  onChangeCheckbox = (ev) => {
    const { name, value } = ev.target;
    this.setState({ [name]: addOrRemove(this.state[name], value) })
  }
  onSubmit = (ev) => {
    ev.preventDefault();
    this.setState({ errors: {} })
    const { errors, isValid } = validateForm(this.state)
    if (!isValid) return this.setState({ errors })
    let payload = {
      email: this.state.email,
      appName: this.state.appName,
      description: this.state.description,
      message: this.state.message,
      platform: this.state.platform.join(' & '),
    }
    agent.Common.requestApplication(payload).then((result) => {
      this.setState({ email: '', appName: '', description: '', errors: {}, message: result.message })
    }).catch((errors) => { this.setState({ errors }) })
  }
  render() {
    const { email, appName, description, errors, message } = this.state
    return (<footer style={{ padding: "160px 0 0" }} id="tg-footer" className="tg-footer tg-haslayout">
      <div className="tg-footerupcontent">
        <div className="row">
          <div className="col-xs-12 col-sm-7 col-md-7 col-lg-7">
            <h2>Quality Apps</h2>
            <h3>Request an App to be added to the App Store.</h3>
            <nav id="tg-fnav" className="tg-fnav">
              <ul>
                <li><a href={'/home/all'} >Home</a></li>
                <li><a href={'/home/popular'} data-appcount="">Top Downloads</a></li>
                <li><a href={'/home/rated'} data-appcount="">Top Rated Apps</a></li>
              </ul>
            </nav>
          </div>
          <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
            <form className="tg-formtheme tg-formnewsletter" onSubmit={this.onSubmit}>

              <fieldset>
                {message && <div className="alert alert-success">{message}</div>}
                <div className={cx("form-group", { "has-error": errors.email })}>
                  <input type="email" onChange={this.onChange} name="email" className="form-control" placeholder="Your Email Adress" value={email} />
                  <span class="help-block">{errors.email}</span>
                </div>
                <div className={cx("form-group", { "has-error": errors.appName })}>
                  <input type="text" onChange={this.onChange} name="appName" className="form-control" placeholder="Application Name" value={appName} />
                  <span class="help-block">{errors.appName}</span>
                </div>
                <div className={cx("form-group", { "has-error": errors.description })}>
                  <textarea onChange={this.onChange} name="description" className="form-control" placeholder="Short description about new application" value={description}></textarea>
                  <span className="help-block">{errors.description}</span>
                </div>

                <div>
                  <div className={'col-xs-6 col-sm-6 col-md-6 col-lg-6'}>
                    <div className={'form-group'}>

                      <div class="checkbox">
                        <label>
                          <input
                            name="platform"
                            onChange={this.onChangeCheckbox}
                            type="checkbox"
                            value="TouchIT Y Series LED"
                          />
                          TouchIT Y Series LED
                  </label>
                      </div>
                    </div>
                  </div>

                  <div className={'col-xs-6 col-sm-6 col-md-6 col-lg-6'}>
                    <div className={'form-group'}>
                      <div class="checkbox">
                        <label>
                          <input
                            name="platform"
                            onChange={this.onChangeCheckbox}
                            type="checkbox"
                            value="TouchIT Rapide LED"
                          />
                          TouchIT Rapide LED
                    </label>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className="tg-btn">Request Application</button>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </footer>);
  }
};

export default Footer;