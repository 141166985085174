import AppList from '../AppList';
import React from 'react';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
  ...state.appList,
  tags: state.home.tags,
  currentUser: state.common.currentUser,
  token: state.common.token
});

const MainView = props => {
  return (
    <AppList
      activeTag={props.activeTag}
      currentUser={props.currentUser}
      pager={props.pager}
      apps={props.apps}
      loading={props.apps}
      appsCount={props.appsCount}
      currentPage={props.currentPage} />
  );
};

export default connect(mapStateToProps, null)(MainView);
