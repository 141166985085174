import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

export default function validateInput(data) {
    const errors = {};

    if (Validator.isEmpty(data.appId)) {
        errors.appId = 'This field is required';
    }

    if (Validator.isEmpty(data.title)) {
        errors.title = 'This field is required';
    }
    if (Validator.isEmpty(data.summary)) {
        errors.summary = 'This field is required';
    }
    if (Validator.isEmpty(data.description)) {
        errors.description = 'This field is required';
    }
    if (!data.icon.length) {
        errors.icon = 'This field is required';
    }
    if (!data.screenshoots.length) {
        errors.screenshoots = 'This field is required';
    }
    if (!data.apk.length) {
        errors.apk = 'This field is required';
    }

    return {
        errors,
        isValid: isEmpty(errors),
    };
}
