import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ListErrors from './ListErrors';
import TextFieldGroup from './common/TextFieldGroup'
import agent from '../agent';
import { HOST } from '../config';
import {
  UPDATE_FIELD_AUTH,
  LOGIN,
  LOGIN_PAGE_UNLOADED
} from '../constants/actionTypes';

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
  onChangeEmail: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'email', value }),
  onChangePassword: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'password', value }),
  onSubmit: (email, password) =>
    dispatch({ type: LOGIN, payload: agent.Auth.login(email, password) }),
  onUnload: () =>
    dispatch({ type: LOGIN_PAGE_UNLOADED }),
  setCurrentUser: (payload) =>
    dispatch({ type: LOGIN, payload })
});

class Login extends React.Component {
  constructor() {
    super();
    this.changeEmail = ev => this.props.onChangeEmail(ev.target.value);
    this.changePassword = ev => this.props.onChangePassword(ev.target.value);
    this.submitForm = (email, password) => ev => {
      ev.preventDefault();
      this.props.onSubmit(email, password);
    };
  }
  componentDidMount() {
    let params = new URLSearchParams(this.props.location.search);
    let success = params.get('success');
    let token = params.get('token');

    if (success) {
      this.props.setCurrentUser(JSON.parse(token))
    }
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const errors = this.props.errors || {};
    const message = this.props.message;
    const email = this.props.email;
    const password = this.props.password;

    return (<div className="tg-fullscreen tg-fullbackground tg-loginarea" id="tg-fullbackground">
      <div style={{ position: 'absolute', zIndex: -1, top: 0, left: 0, bottom: 0, right: 0, overflow: 'hidden', backgroundSize: 'cover', backgroundColor: 'transparent', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 100%', backgroundImage: 'url("images/banner/img-04.jpg")' }}></div>

      <main id="tg-main" className="tg-main tg-haslayout">
        <div className="tg-formarea">
          <form onSubmit={this.submitForm(email, password)} className="tg-formtheme tg-formlogin">
            <h2>login</h2>


            <fieldset>
              {message && <div className="alert alert-danger">{message}</div>}
              {this.props.errors && <div className="alert alert-danger">{this.props.errors.form}</div>}

              <TextFieldGroup
                error={errors.email}
                label="Email"
                onChange={this.changeEmail}
                value={email}
                field="email"
                type="email"
              />

              <TextFieldGroup
                error={errors.password}
                label="Password"
                onChange={this.changePassword}
                value={password}
                field="password"
                type="password"
              />
              <ListErrors errors={this.props.errors} />


              <div className="form-group">
                <button type="submit" className="tg-btn" disabled={this.props.inProgress}>Login</button>
              </div>
              <div className="form-group">
                <ul className="tg-socialicons">
                  <li className="tg-facebook"><a href={HOST + '/api/login-via-facebook'}><i className="fa fa-facebook"></i></a></li>
                  <li className="tg-twitter"><a href={HOST + '/api/login-via-twitter'}><i className="fa fa-twitter"></i></a></li>
                  <li className="tg-googleplus"><a href={HOST + '/api/login-via-google'}><i className="fa fa-google"></i></a></li>
                  <li className="tg-vimeo"><a href={HOST + '/api/login-via-outlook'}><i className="fa fa-windows"></i></a></li>
                </ul>
              </div>

              <div className="form-group">
                <span><Link to="/forgot_password">Forgotten Password ?</Link></span>
              </div>

            </fieldset>
          </form>
          <div className="tg-description">
            <p>Not Registered? <Link to="/register">Create an Account</Link></p>
          </div>
        </div>
      </main>
    </div>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
