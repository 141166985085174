import { SCHOOL_LIST_PAGE_LOAD, SCHOOL_LIST_PAGE_UNLOAD } from "../constants/actionTypes";

export default (state = {}, action) => {
    switch (action.type) {
        case SCHOOL_LIST_PAGE_LOAD:
            return {
                ...state,
                schoolList: action.payload[0].schoolList,
                schoolCount: action.payload[0].schoolCount
            }
        case SCHOOL_LIST_PAGE_UNLOAD:
            return {}
        default:
            return state;
    }

}