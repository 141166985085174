import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom'
import cx from 'classnames'
import agent from '../../agent';
import { CHANGE_TAB, UPDATE_FIELD_SEARCH_FORM, SEARCH_FORM_SUBMITTED } from '../../constants/actionTypes';

const mapStateToProps = state => ({
  ...state.appList,
  tags: state.home.tags,
  token: state.common.token,
  tab: state.appList.tab,
  tag: state.appList.tag
});

const mapDispatchToProps = dispatch => ({
  onTabClick: (tab, pager, payload) => dispatch({ type: CHANGE_TAB, tab, pager, payload }),
  onUpdateField: (key, value) => dispatch({ type: UPDATE_FIELD_SEARCH_FORM, key, value }),
  onSubmit: payload => dispatch({ type: SEARCH_FORM_SUBMITTED, payload }),
});


class NavBar extends React.Component {
  constructor(props) {
    super(props)

    this.handleScroll = this.handleScroll.bind(this)
    this.state = {
      search: false,
      tag: ''
    }

    this.props.history.listen((location, action) => {
      // location is an object like window.location
      console.log(action, location.pathname, location.state)
    });
  }
  // componentDidMount() {
  //   window.addEventListener('scroll', this.handleScroll);

  //   let params = new URLSearchParams(this.props.location.search);
  //   let type = params.get('type');

  // }

  // componentWillMount() {

  //   if (this.props.tab) {
  //     this.props.onTabClick(this.props.tab, agent.Applications.all, agent.Applications[this.props.tab]());

  //   } else {
  //     this.props.onTabClick('all', agent.Applications.all, agent.Applications.all());
  //   }
  // }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  onRouteChanged() {
    console.log("ROUTE CHANGED");
  }

  componentWillReceiveProps(props) {
    let params = new URLSearchParams(this.props.location.search);
    let type = params.get('type') || 'all';

    if (this.props.tab !== type && !this.props.tag) {
      this.props.onTabClick(type, agent.Applications[type], agent.Applications[type]());
    }

    if (window.location.pathname.indexOf('/app/') > -1) {
      if (this.state.search) {
        this.setState({ search: false, tag: this.props.tag })
      }
    }
  }

  handleScroll() {
    let sticky = this.navbar.offsetTop;

    if (window.pageYOffset > sticky + 200) {
      this.navbar.classList.add('tg-sticky');
    } else {
      this.navbar.classList.remove('tg-sticky');
    }
  }

  onTabClick(tab) {
    this.props.history.push({
      pathname: '/',
      search: `?type=${tab}`,
    })

    this.props.onTabClick(tab, agent.Applications[tab], agent.Applications[tab]());
  }

  onChange = ev => {
    this.props.onUpdateField([ev.target.name], ev.target.value);
  }

  submitForm = ev => {
    ev.preventDefault();

    if (this.props.search) {
      this.props.onTabClick('search', agent.Applications.search, agent.Applications.search(1, this.props.search, this.props.tag || this.state.tag));
    }
    // if (this.props.) {
    //   this.setState({ errors: {}, isLoading: true });

    //   const { appSlug, appId, title, summary, description, icon, screenshoots, apk, video, price, tagList } = this.props;

    //   let data = { appSlug, appId, title, summary, description, video, price, tagList }
    //   let files = { icon: [icon[0]], apk: [apk[0]], screenshoots: screenshoots }

    //   const myApp = {};

    //   const slug = { slug: this.props.appSlug };
    //   const promise = this.props.appSlug ?
    //     agent.Applications.updateMyApp(Object.assign(myApp, slug)) :
    //     agent.Applications.createMyApp(data, files);

    //   this.props.onSubmit(promise);
    // }



  };

  toggleSearch = () => {
    this.setState({ search: !this.state.search, tag: this.props.tag }, () => {
      if (!this.state.search) {
        let params = new URLSearchParams(this.props.location.search);
        let type = params.get('type') || 'all';

        this.props.onTabClick(type, agent.Applications[type], agent.Applications[type]());
      }
    })
  }


  render() {
    return (<nav ref={navbar => this.navbar = navbar} id="tg-nav" className="tg-nav">
      <div className="navbar-header">
        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#tg-navigation" aria-expanded="false">
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </button>
      </div>
      <div id="tg-navigation" className="collapse navbar-collapse tg-navigation">
        <ul>
          <li className={this.props.tab === 'all' ? 'tg-active' : null} onClick={this.onTabClick.bind(this, 'all')}><a href={null} data-appcount={this.props.appsCount}>Home</a></li>
          {/* <li className={this.props.tab === 'categories' ? 'tg-active' : null}><a onClick={this.onTabClick.bind(this, 'categories')} href={null} data-appcount="1200">Categories</a></li> */}
          <li className={this.props.tab === 'popular' ? 'tg-active' : null} onClick={this.onTabClick.bind(this, 'popular')}><a href={null} data-appcount={Math.round(this.props.appsCount / 3)}>Top Downloads</a></li>
          {/* <li className={this.props.tab === 'new' ? 'tg-active' : null}><a onClick={this.onTabClick.bind(this, 'new')} href={null} data-appcount="100">New Apps</a></li> */}
          <li className={this.props.tab === 'rated' ? 'tg-active' : null} onClick={this.onTabClick.bind(this, 'rated')}><a href={null} data-appcount={Math.round(this.props.appsCount / 4)}>Top Rated Apps</a></li>
          {this.props.token ? <li className={this.props.tab === 'favorited' ? 'tg-active' : null} onClick={this.onTabClick.bind(this, 'favorited')}><a href={null} data-appcount="">wishlist</a></li> : null}
          {/* {this.props.token ? <li className={this.props.tab === 'search' ? 'tg-active' : null} onClick={this.onTabClick.bind(this, 'search')}><a href={null} data-appcount="">Search Result</a></li> : null} */}
          {/* {this.props.tab === 'search' ? <li className={this.props.tab === 'search' ? 'tg-active' : null} onClick={this.onTabClick.bind(this, 'search')}>Search Result</li> : null} */}
          <li onClick={this.toggleSearch} ><Link to={'/'} ><i className="fa fa-search"></i></Link></li>
        </ul>
      </div>
      <form className={cx("tg-formtheme tg-formsearch", { ['tg-showsearch']: this.state.search })} onSubmit={this.submitForm}>
        <fieldset>
          <input className="form-control" onChange={this.onChange} value={this.props.search} name="search" type="search" placeholder="Search here..." />
          {/* <button className="tg-btnsearch" type="submit"><span className="glyphicon glyphicon-search"></span></button> */}
          <a onClick={this.toggleSearch} href={null} className="tg-btnclose"><span className="fa fa-times"></span></a>
        </fieldset>
      </form>
    </nav>);
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));