import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import TextFieldGroup from './common/TextFieldGroup/TextFieldGroup'
import agent from '../agent';
import {
  UPDATE_FIELD_AUTH,
  RESET_PASSWORD,
  LOGIN_PAGE_UNLOADED
} from '../constants/actionTypes';

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
  onChangeEmail: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'email', value }),
  onChangePassword: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'password', value }),
  onSubmit: (password, token) =>
    dispatch({ type: RESET_PASSWORD, payload: agent.Auth.resetPassword(password, token) }),
  onUnload: () =>
    dispatch({ type: LOGIN_PAGE_UNLOADED })
});

class Login extends React.Component {
  constructor() {
    super();
    this.changePassword = ev => this.props.onChangePassword(ev.target.value);
    this.submitForm = (password) => ev => {
      ev.preventDefault();
      debugger
      let token = this.props.match.params.token
      this.props.onSubmit(password, token);
    };
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const errors = this.props.errors || {};
    const message = this.props.message;
    const password = this.props.password;
    return (<div className="tg-fullscreen tg-fullbackground tg-resetpassword" id="tg-fullbackground">
      <div style={{ position: 'absolute', zIndex: -1, top: 0, left: 0, bottom: 0, right: 0, overflow: 'hidden', backgroundSize: 'cover', backgroundColor: 'transparent', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 100%', backgroundImage: 'url("images/banner/img-04.jpg")' }}></div>
      {/* <div className="tg-resetbackgound"></div> */}

      <main id="tg-main" className="tg-main tg-haslayout">
        <div className="tg-formarea">
          <form onSubmit={this.submitForm(password)} className="tg-formtheme tg-formlogin tg-formresetkey">
            <h2>Enter New Password</h2>
            <fieldset>
              {message && <div className="alert alert-success">{message}</div>}
              {this.props.errors && <div className="alert alert-danger">{this.props.errors.form}</div>}
              <TextFieldGroup
                error={errors.password}
                label="New Password"
                onChange={this.changePassword}
                value={password}
                field="password"
                type="password"
              />
              <div className="form-group">
                <button type="submit" className="tg-btn" disabled={this.props.inProgress}>Reset Password</button>
              </div>
              <div className="form-group">
                <span>If You Have an Account? <Link to="/login">Signin</Link></span>
              </div>
            </fieldset>
          </form>
          <div className="tg-description">
            <p>Not Registered? <Link to="/register">Create an Account</Link></p>
          </div>
        </div>
      </main>
    </div>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);