// import Banner from '../Layout/Banner';
// import NavBar from '../Layout/NavBar';
import MainView from './MainView';
import React from 'react';
import Tags from './Tags';
import agent from '../../agent';
import { connect } from 'react-redux';
import {
  HOME_PAGE_LOADED,
  HOME_PAGE_UNLOADED,
  APPLY_TAG_FILTER,
  CHANGE_TAB
} from '../../constants/actionTypes';

const Promise = global.Promise;

const mapStateToProps = state => ({
  ...state.home,
  appName: state.common.appName,
  token: state.common.token,
  tab: state.appList.tab,
  tag: state.appList.tag,
  search: state.appList.search
});

const mapDispatchToProps = dispatch => ({
  onClickTag: (tag, pager, payload) =>
    dispatch({ type: APPLY_TAG_FILTER, tag, pager, payload }),
  onLoad: (tab, pager, payload) =>
    dispatch({ type: HOME_PAGE_LOADED, tab, pager, payload }),
  onUnload: () =>
    dispatch({ type: HOME_PAGE_UNLOADED }),
  onTabClick: (tab, pager, payload) => dispatch({ type: CHANGE_TAB, tab, pager, payload })
});

class Home extends React.Component {
  constructor(props) {
    super(props)

    this.fetchAllApps = this.fetchAllApps.bind(this);
  }
  componentDidMount() {
    let tab = this.props.tab;

    this.props.onLoad(tab, agent.Applications.all, Promise.all([agent.Tags.getAll(), agent.Applications.all()]));

  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  fetchAllApps() {
    // let tab = this.props.tab;
    this.props.onTabClick('all', agent.Applications.all, agent.Applications.all());
    // this.props.onLoad(tab, agent.Applications.all, Promise.all([agent.Tags.getAll(), agent.Applications.all()]));
  }

  render() {
    return (<main id="tg-main" className="tg-main tg-haslayout">
      <div id="tg-twocolumns" className="tg-twocolumns">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pull-right">
            <aside id="tg-sidebar" className="tg-sidebar">
              <div className="tg-widget">
                <div className="tg-widgettitle">
                  <h3>Categories</h3>
                </div>
                <div className="tg-widgetcontent">
                  <Tags
                    active={this.props.tag}
                    tags={this.props.tags}
                    onClickTag={this.props.onClickTag}
                    onTabClick={this.props.onTabClick}
                    search={this.props.search}
                    onResetCategoryFilter={this.fetchAllApps} />
                </div>
              </div>
            </aside>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pull-left">
            <div id="tg-content" className="tg-content">
              <MainView activeTag={this.props.tag} />
            </div>
          </div>
        </div>
      </div>
    </main>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Home);
