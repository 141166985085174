import React from "react";
import { Layout, Row, Col, Popover, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";
import RequestForm from "./RequestForm";
const { Header } = Layout;

const LoggedOutView = (props) => {
  if (!props.currentUser) {
    return (
      <ul className="gx-login-list">
        <li>
          <Link to="/login">
            <span>Signin</span>
          </Link>
        </li>
        <li>
          <Link to="/register">
            <span>Signup</span>
          </Link>
        </li>

        <li>
          <Popover
            title="Request Application"
            overlayClassName="gx-popover-horizantal"
            placement="bottomRight"
            content={<RequestForm />}
            trigger="click"
          >
            <span style={{ marginTop: 10 }} className="gx-pointer gx-d-block">
              <Button type="primary" size="middle">
                Request Application
              </Button>
            </span>
          </Popover>
        </li>
      </ul>
    );
  }
  return null;
};

const LoggedInView = (props) => {
  if (props.currentUser) {
    return (
      <ul className="gx-login-list">
        {props.currentUser.role === "admin" ? (
          <li>
            <Link to="/googleapp">
              <span>Add Google Application</span>
            </Link>
          </li>
        ) : null}

        {props.currentUser.role === "admin" ? (
          <li>
            <Link to="/package">
              <span>Upload Application</span>
            </Link>
          </li>
        ) : null}

        <li>
          <Link to="/settings">
            <span>Settings</span>
          </Link>
        </li>

        <li>
          <a href={null}>
            <img
              className="nsg-dppreview"
              src={
                props.currentUser.image ||
                `https://dummyimage.com/40x40/3876b4/ffffff.png&text=${props.currentUser.username}`
              }
              alt={props.currentUser.username}
            />
            <span>{props.currentUser.username}</span>
          </a>
        </li>
        {props.currentUser.role !== "admin" ? (
          <li>
            <Popover
              title="Request Application"
              overlayClassName="gx-popover-horizantal"
              placement="bottomRight"
              content={<RequestForm />}
              trigger="click"
            >
              <span style={{ marginTop: 10 }} className="gx-pointer gx-d-block">
                <Button type="primary" size="middle">
                  Request Application
             </Button>
              </span>
            </Popover>
          </li>
        ) : null}
      </ul>
    );
  }

  return null;
};

class AppHeader extends React.Component {
  render() {
    return (
      <div className="gx-header-horizontal">
        <div className="ant-layout-header  gx-header-horizontal-top">
          <div className="gx-container">
            <div className="gx-header-horizontal-top-flex">
              <div className="gx-header-horizontal-top-left">
                <p className="gx-mb-0 gx-text-truncate">
                  <Link to="/" className="gx-pointer">
                    <img
                      style={{ height: 50 }}
                      src="/images/logo.png"
                      alt={this.props.appName.toLowerCase()}
                    />
                  </Link>
                </p>
              </div>
              <LoggedOutView currentUser={this.props.currentUser} />
              <LoggedInView currentUser={this.props.currentUser} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AppHeader;
