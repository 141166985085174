import React, { Fragment } from 'react';
import { connect } from "react-redux";
import map from 'lodash/map'
import Popup from './common/Popup'
import SchoolAccount from './dashbaord/SchoolAccount'
import UsersPagination from './UsersPagination'
import agent from "../agent";
import { HOST } from '../config'

import { USERS_LIST_PAGE_LOAD, USERS_LIST_PAGE_UNLOAD } from "../constants/actionTypes";

const mapStateToProps = state => ({
    ...state.user,
    token: state.common.token,
    currentUser: state.common.currentUser,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) => dispatch({ type: USERS_LIST_PAGE_LOAD, pager, payload }),
    onUnload: (pager, payload) => dispatch({ type: USERS_LIST_PAGE_UNLOAD, pager, payload })
});

// const Dashboard = props => (
class Users extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            schoolId: '',
            popup: {
                visible: false,
                type: ''
            }
        }

    }
    componentWillMount() {
        this.fetchUserData();
    }
    componentWillUnmount() {
        this.props.onUnload();
    }

    fetchUserData = () => {
        // if (this.props.location.pathname.split('/')[2]) {
        // this.setState({ schoolId: this.props.location.pathname.split('/')[2] });

        // this.props.onLoad(agent.Users.bySchool, Promise.all([agent.Users.bySchool(this.props.location.pathname.split('/')[2])]));
        // } else {
        this.props.onLoad(agent.Users.all, Promise.all([agent.Users.all()]));
        // }
    }

    togglePopup = (type) => {
        this.setState({ popup: { visible: !this.state.popup.visible, type: type } })
    }

    deleteUser = async (user) => {
        await agent.Users.deleteUser(user._id)
        this.fetchUserData()
    }

    downloadUserList = async () => {
        let pdf = await agent.Users.createEmailPdf();
        var anchor = document.createElement('a');
        anchor.href = `${HOST}/${pdf.name}`;
        anchor.target = '_blank';
        anchor.download = pdf.name;
        anchor.click();
    }

    render() {
        const { popup } = this.state
        const { currentUser } = this.props
        const users = map(this.props.usersList, (val, key) => <tr>
            <th scope="row">{key + 1}</th>
            <td>{val.username}</td>
            <td>{val.email}</td>
            <td>{val.role}</td>
            <td><a onClick={this.deleteUser.bind(this, val)} className="nsg-delbtn">Delete</a></td>
        </tr>
        );
        return (
            <Fragment>
                <Popup isOpen={popup.visible} title={'Add Users'}>
                    <SchoolAccount togglePopup={this.togglePopup} fetchUserData={this.fetchUserData} />
                </Popup>

                {/* <Popup isOpen={popup.visible} title={'Delete User'}>
                    <h1>Are you sure you want to delete ?</h1>
                    <button className="btn at-themebtn">Yes</button>
                    <button className="btn at-themebtn">No</button>
                </Popup> */}

                <div className="pull-right">
                    <button className="tg-btn nsg-users-btn" onClick={this.togglePopup}>Add Users</button>

                    {currentUser.role === 'admin' ?
                        <button className="tg-btn nsg-users-btn" onClick={this.downloadUserList}>Download Users Email</button>
                        : null}
                </div>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Username</th>
                            <th scope="col">Email</th>
                            <th scope="col">Role</th>
                            <th scope="col">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users}
                    </tbody>
                </table>

                {/* {!this.state.schoolId ? */}
                <UsersPagination
                    pager={this.props.pager}
                    usersCount={this.props.usersCount}
                    currentPage={this.props.currentPage}
                    schoolId={this.state.schoolId}

                />
                {/* : null} */}

            </Fragment>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Users);