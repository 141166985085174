import React, { Fragment } from 'react';
import { connect } from "react-redux";
import map from 'lodash/map'
import Popup from './common/Popup/Popup'
import NewSchool from './dashbaord/NewSchool'
import Alert from './common/alert'
import SchoolAccount from './dashbaord/SchoolAccount'

import agent from "../agent";

import { SCHOOL_LIST_PAGE_LOAD, SCHOOL_LIST_PAGE_UNLOAD } from "../constants/actionTypes";

const mapStateToProps = state => ({
    ...state.school,
    token: state.common.token,
    currentUser: state.common.currentUser,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) => dispatch({ type: SCHOOL_LIST_PAGE_LOAD, pager, payload }),
    onUnload: (pager, payload) => dispatch({ type: SCHOOL_LIST_PAGE_UNLOAD, pager, payload })
});

// const Dashboard = props => (
class School extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            popup: {
                visible: false,
                type: ''
            },
            confirmationBox: {
                visible: false,
                item: ''
            }
        }

    }

    componentWillMount() {
        this.props.onLoad(agent.School.all, Promise.all([agent.School.all()]));
    }
    componentWillUnmount() {
        this.props.onUnload();
    }

    togglePopup = (type) => {
        this.setState({ popup: { visible: !this.state.popup.visible, type: type } })
    }
    toggleConfirmation = (item) => {
        this.setState({ confirmationBox: { visible: !this.state.confirmationBox.visible, item: item } })
    }

    confirmDelete = () => {
        const { item } = this.state.confirmationBox
        if (item._id) {
            agent.School.delete(item._id).then((res) => {
                this.props.onLoad(agent.School.all, Promise.all([agent.School.all()]));
                this.toggleConfirmation()

            }, (err) => {
                let errors = JSON.parse(err.response.text)
                this.setState({ errors: errors.errors, isLoading: false })
            });
        }

    }

    render() {
        const { popup, confirmationBox } = this.state
        const schools = map(this.props.schoolList, (val, key) => <tr>
            <th scope="row">{key + 1}</th>
            <td>{val.name}</td>
            <td>
                <button onClick={this.toggleConfirmation.bind(this, val)}>Delete</button>
            </td>
        </tr>
        );
        return (
            <Fragment>
                <Popup isOpen={popup.visible} title={popup.type === 'school' ? 'Create New School' : popup.type === 'schoolAccount' ? 'Create School Account' : ''}>
                    {popup.type === 'school' ? <NewSchool togglePopup={this.togglePopup} /> : popup.type === 'schoolAccount' ? <SchoolAccount togglePopup={this.togglePopup} /> : ''}
                </Popup>
                <Alert
                    onToggle={this.toggleConfirmation}
                    isOpen={confirmationBox.visible}
                    title={`Delete School`}
                    onOK={this.confirmDelete}
                    onCancel={this.toggleConfirmation}
                />


                <div className="pull-right">
                    <button className="tg-btn nsg-users-btn" onClick={this.togglePopup.bind(this, 'school')}>Add New School</button>
                    <button className="tg-btn nsg-users-btn" onClick={this.togglePopup.bind(this, 'schoolAccount')}>Create School Account</button>
                </div>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {schools}
                    </tbody>
                </table>
            </Fragment>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(School);