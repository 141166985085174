import agent from "../agent";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { APP_LOAD, REDIRECT } from "../constants/actionTypes";
import { Route, Switch } from "react-router-dom";
import AppComponent from "../components/App/index";
import GoogleApp from "../components/GoogleApp";
import PackageUpload from "../components/PackageUpload";
import EditApp from "./EditApp";
import Home from "../components/Home";
import Login from "../components/Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
// import Profile from '../components/Profile';
// import ProfileFavorites from '../components/ProfileFavorites';
import Register from "../components/Register";
import Settings from "../components/Settings";
import Users from "./Users";
import Schools from "./Schools";
import Header from "./Header";

import {
  Main as MainLayout,
  Empty as EmptyLayout,
  Dashboard as DashboardLayout,
} from "../components/Layout";
import {
  HomePage as HomePageBanner,
  DetailPage as DetailPageBanner,
  EmptyPage as EmptyPageBanner,
} from "../components/Layout/Banner";
import { store } from "../store";
import { push } from "react-router-redux";

import { Layout, Row, Col } from "antd";
const { Content, Footer } = Layout;
const mapStateToProps = (state) => {
  return {
    appLoaded: state.common.appLoaded,
    appName: state.common.appName,
    currentUser: state.common.currentUser,
    redirectTo: state.common.redirectTo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload, token) =>
    dispatch({ type: APP_LOAD, payload, token, skipTracking: true }),
  onRedirect: () => dispatch({ type: REDIRECT }),
});

const AppRoute = ({
  component: Component,
  layout: Layout,
  banner: Banner,
  ...rest
}) => (
    <Route
      {...rest}
      render={(props) => (
        <Layout Banner={Banner}>
          <Component {...props} />
        </Layout>
      )}
    />
  );

class App extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.redirectTo) {
      // this.context.router.replace(nextProps.redirectTo);
      store.dispatch(push(nextProps.redirectTo));
      this.props.onRedirect();
    }
  }

  componentWillMount() {
    const token = window.localStorage.getItem("jwt");
    if (token) {
      agent.setToken(token);
    }

    this.props.onLoad(token ? agent.Auth.current() : null, token);
  }

  render() {
    if (this.props.appLoaded) {
      return (
        <Layout>
          <Layout>
            <Header
              appName={this.props.appName}
              currentUser={this.props.currentUser}
            />
            <Content className={`gx-layout-content gx-container-wrap`}>
              <Switch>
                <AppRoute
                  exact
                  path="/"
                  layout={MainLayout}
                  banner={HomePageBanner}
                  component={Home}
                />
                <AppRoute
                  exact
                  path="/home/:tab"
                  layout={MainLayout}
                  banner={HomePageBanner}
                  component={Home}
                />
                <AppRoute
                  path="/login"
                  layout={EmptyLayout}
                  component={Login}
                />
                <AppRoute
                  path="/forgot_password"
                  layout={EmptyLayout}
                  component={ForgotPassword}
                />
                <AppRoute
                  path="/reset_password/:token"
                  layout={EmptyLayout}
                  component={ResetPassword}
                />
                <AppRoute
                  path="/register"
                  layout={EmptyLayout}
                  component={Register}
                />

                <AppRoute
                  path="/googleapp/:slug"
                  layout={EmptyLayout}
                  banner={EmptyPageBanner}
                  component={GoogleApp}
                />
                <AppRoute
                  path="/googleapp"
                  layout={DashboardLayout}
                  banner={HomePageBanner}
                  component={GoogleApp}
                />

                <AppRoute
                  path="/package"
                  layout={DashboardLayout}
                  banner={HomePageBanner}
                  component={PackageUpload}
                />

                <AppRoute
                  path="/users"
                  layout={DashboardLayout}
                  banner={HomePageBanner}
                  component={Users}
                />
                <AppRoute
                  path="/user/:schoolId"
                  layout={DashboardLayout}
                  banner={HomePageBanner}
                  component={Users}
                />
                <AppRoute
                  path="/schools"
                  layout={DashboardLayout}
                  banner={HomePageBanner}
                  component={Schools}
                />

                <AppRoute
                  path="/app/:id"
                  layout={MainLayout}
                  banner={DetailPageBanner}
                  component={AppComponent}
                />
                <AppRoute
                  path="/edit/:id"
                  layout={DashboardLayout}
                  banner={EmptyPageBanner}
                  component={EditApp}
                />

                <AppRoute
                  path="/settings"
                  layout={DashboardLayout}
                  banner={EmptyPageBanner}
                  component={Settings}
                />
                {/* <AppRoute path="/@:username/favorites" layout={MainLayout} banner={EmptyPageBanner} component={ProfileFavorites} /> */}
                {/* <AppRoute path="/@:username" layout={EmptyLayout} banner={EmptyPageBanner} component={Profile} /> */}
              </Switch>

              <Footer style={{ background: '#01366c', color: '#ffffff' }}>
                <div className="gx-layout-footer-content">
                  <Row align="middle">
                    <Col flex={4}>
                      TouchIT Technologies © {(new Date().getFullYear())}. All rights reserved.
                    </Col>
                    <Col flex={1}>
                      <ul className="tg-socialicons" style={{ padding: 0, margin: 0 }}>
                        <li className="tg-facebook"><a href="https://www.facebook.com/touchittechnologies" target="_blank" rel="noopener noreferrer" ><i className="fa fa-facebook" /></a></li>
                        <li className="tg-twitter"><a href="https://twitter.com/touchit_tech" target="_blank" rel="noopener noreferrer" ><i className="fa fa-twitter" /></a></li>
                        <li className="tg-youtube"><a href="https://www.youtube.com/user/TouchITtechnologies" target="_blank" rel="noopener noreferrer" ><i className="fa fa-youtube" /></a></li>
                        <li className="tg-linkedin"><a href="https://vimeo.com/touchittechnologies" target="_blank" rel="noopener noreferrer" ><i className="fa fa-vimeo" /></a></li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Footer>
            </Content>
          </Layout>
        </Layout>
      );
    }
    return (
      <Header
        appName={this.props.appName}
        currentUser={this.props.currentUser}
      />
    );
  }
}

// App.contextTypes = {
//   router: PropTypes.object.isRequired
// };

export default connect(mapStateToProps, mapDispatchToProps)(App);
