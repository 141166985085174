import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import { HOST } from './config';

const superagent = superagentPromise(_superagent, global.Promise);

// const API_ROOT = 'http://159.203.76.73:3002/api';
const API_ROOT = `${HOST}/api`;

const encode = encodeURIComponent;
const responseBody = res => res.body;

let token = null;
const tokenPlugin = req => {
  if (token) {
    req.set('authorization', `Token ${token}`);
  }
}

const requests = {
  del: url =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: url =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url, body) =>
    superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  attachment: (url, body, files) => {
    let req = superagent.post(`${API_ROOT}${url}`).use(tokenPlugin);
    Object.keys(body).forEach(item => {
      req.field(item, body[item])
    });

    Object.keys(files).forEach((item) => {
      files[item].forEach(file => {
        req.attach(item, file)
      });
    })

    return req.then(responseBody);

    // req.end(responseBody);

    // .set('Content-Type', 'multipart/form-data')
    // .attach(body)
    // 
  }

};

const Auth = {
  current: () =>
    requests.get('/user'),
  login: (email, password) =>
    requests.post('/users/login', { user: { email, password } }),
  register: (username, email, password) =>
    requests.post('/users', { user: { username, email, password } }),
  resetPasswordRequest: (email) => requests.post('/reset_password_request', { email }),
  resetPassword: (password, token) => requests.post('/reset_password', { data: { password, token } }),
  save: user =>
    requests.put('/user', { user })
};

const Tags = {
  getAll: () => requests.get('/tags')
};

const School = {
  create: school => requests.post('/school', { school }),
  createAdmin: data => requests.post('/school/admin', data),
  all: page => requests.get(`/school/list?${limit(30, page)}`),
  delete: (schoolId) => requests.del(`/school/${schoolId}`),
};


const Common = {
  requestApplication: (data) => requests.post('/application_request', data)
};

const limit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;
const omitSlug = article => Object.assign({}, article, { slug: undefined })

// const Articles = {
//   all: page =>
//     requests.get(`/articles?${limit(30, page)}`),
//   byAuthor: (author, page) =>
//     requests.get(`/articles?author=${encode(author)}&${limit(15, page)}`),
//   byTag: (tag, page) =>
//     requests.get(`/articles?tag=${encode(tag)}&${limit(30, page)}`),
//   del: slug =>
//     requests.del(`/articles/${slug}`),
//   favorite: slug =>
//     requests.post(`/articles/${slug}/favorite`),
//   favoritedBy: (author, page) =>
//     requests.get(`/articles?favorited=${encode(author)}&${limit(15, page)}`),
//   feed: () =>
//     requests.get('/articles/feed?limit=20&offset=0'),
//   get: slug =>
//     requests.get(`/articles/${slug}`),
//   unfavorite: slug =>
//     requests.del(`/articles/${slug}/favorite`),
//   update: article =>
//     requests.put(`/articles/${article.slug}`, { article: omitSlug(article) }),
//   create: article =>
//     requests.post('/articles', { article })
// };

const Applications = {
  all: page =>
    requests.get(`/apps?${limit(30, page)}&platform=web`),
  byAuthor: (author, page) =>
    requests.get(`/apps?author=${encode(author)}&${limit(15, page)}&platform=web`),
  byTag: (tag, page) =>
    requests.get(`/apps?tag=${encode(tag)}&${limit(30, page)}&platform=web`),
  del: slug =>
    requests.del(`/apps/${slug}`),
  favorite: slug =>
    requests.post(`/apps/${slug}/favorite`),
  favorited: (page) =>
    requests.get(`/apps?favorited=${limit(30, page)}&platform=web`),
  popular: (page) =>
    requests.get(`/apps?popular=${limit(30, page)}&platform=web`),
  rated: (page) =>
    requests.get(`/apps?rated=${limit(30, page)}&platform=web`),
  search: (page, search, tag) =>
    requests.get(`/apps?${limit(30, page)}&offset=0&platform=web&search=${search}&tagList=${tag}`),
  feed: () =>
    requests.get('/apps/feed?limit=20&offset=0'),
  get: slug =>
    requests.get(`/apps/${slug}`),
  unfavorite: slug =>
    requests.del(`/apps/${slug}/favorite`),
  update: article =>
    requests.put(`/apps/${article.slug}`, { article: omitSlug(article) }),
  create: article =>
    requests.post('/apps', { article }),

  updateMyApp: myApp =>
    requests.put(`/apps/${myApp.slug}/myApp`, { myApp: omitSlug(myApp) }),
  createMyApp: (data, files) =>
    requests.attachment('/apps/myApp', data, files),

  updateGoogleApp: slug =>
    requests.put(`/apps/${slug}/googleApp`),
  updateDetails: data => requests.put(`/apps/updatedetails`, data),
  updateDetailsAndApk: (data, apk) => requests.attachment(`/apps/updatedetails`, data, apk),
  createGoogleApp: googleApp =>
    requests.post('/apps/googleApp', { googleApp })
};

const Dashbaord = {
  meta: () => requests.get(`/dashboard/meta`)
}
const Users = {
  bySchool: (schoolId, page) => requests.get(`/userlist?${limit(30, page)}&schoolId=${schoolId}`),
  all: page => requests.get(`/userlist?${limit(30, page)}`),
  deleteUser: (id) => requests.del(`/userlist/${id}`),
  createEmailPdf: () => requests.get(`/emailPdf`),
}


const Comments = {
  create: (slug, comment) =>
    requests.post(`/apps/${slug}/comments`, { comment }),
  delete: (slug, commentId) =>
    requests.del(`/apps/${slug}/comments/${commentId}`),
  forArticle: slug =>
    requests.get(`/apps/${slug}/comments`)
};

const Profile = {
  follow: username =>
    requests.post(`/profiles/${username}/follow`),
  get: username =>
    requests.get(`/profiles/${username}`),
  unfollow: username =>
    requests.del(`/profiles/${username}/follow`)
};

export default {
  Users,
  Dashbaord,
  Applications,
  Auth,
  Comments,
  Common,
  School,
  Profile,
  Tags,
  setToken: _token => { token = _token; }
};
