import React, { Fragment } from 'react';
import { connect } from 'react-redux';


import NavBar from "./NavBar";
import Footer from "./Footer";

const mapStateToProps = state => {
    return {
        appLoaded: state.common.appLoaded,
        appName: state.common.appName,
        token: state.common.token,
    }
};



class Main extends React.Component {
    render() {
        const { Banner } = this.props
        return (
            <Fragment>
                <Banner appName={this.props.appName} />
                <NavBar token={this.props.token} />
                {this.props.children}
                <Footer />

            </Fragment>
        )

    }
}

export default connect(mapStateToProps, null)(Main);