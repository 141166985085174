import React, { Component } from 'react';

import TextFieldGroup from '../common/TextFieldGroup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames'
import Validator from 'validator'
import isEmpty from 'lodash/isEmpty'
import agent from '../../agent'

function validateInput(data) {
    let errors = {};

    if (Validator.isEmpty(data.name)) {
        errors.name = 'This field is required';
    }

    return {
        errors,
        isValid: isEmpty(errors)
    }

}

class NewSchool extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            errors: {},
            isLoading: false,
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount = () => {
        // this.props.addFlashMessage({
        //    type: 'success',
        //    text: 'Email for password reset has been sent successfully. ',
        //  });
    }
    isValid = () => {
        const { errors, isValid } = validateInput(this.state);

        if (!isValid) {
            this.setState({ errors });
        }

        return isValid;
    }

    onSubmit(e) {
        e.preventDefault();
        if (this.isValid()) {
            this.setState({ errors: {}, isLoading: true });
            agent.School.create(this.state).then((res) => {
                this.setState({ name: '', errors: '', isLoading: false });

                this.props.togglePopup();
            }, (err) => {
                let errors = JSON.parse(err.response.text)
                this.setState({ errors: errors.errors, isLoading: false })
            });
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const { errors, name, isLoading } = this.state;
        const { togglePopup } = this.props;
        return (<form autoComplete="off" onSubmit={this.onSubmit}>
            {errors.form && <div className={"alert alert-danger"}>{errors.form}</div>}
            <TextFieldGroup
                field="name"
                label="School Name"
                value={name}
                error={errors.name}
                onChange={this.onChange}
            />
            <div className={'right-footer'}>
                <button type="submit" color="success" className={cx("btn at-themebtn")} disabled={isLoading}>Create New School</button>
                <button type="button" color="danger" className={cx("btn at-themebtn")} onClick={togglePopup}>Cancel</button>
            </div>
        </form>);
    }
}

NewSchool.propTypes = {
    togglePopup: PropTypes.func.isRequired,
};

export default connect(null, {})(NewSchool);