import { Card, Row, Col, } from "antd";
import AppPreview from './AppPreview';
import ListPagination from './ListPagination';
import React from 'react';

const AppList = props => {
  if (!props.apps) {
    return (
      <div className="article-preview">Loading...</div>
    );
  }

  if (props.apps.length === 0) {
    return (
      <div className="article-preview">
        No app is uploaded here... yet.
      </div>
    );
  }

  return (
    <section className="tg-sectionspace tg-haslayout">
      <div className="row">
        <Row>
          {
            props.apps.map((app, index) => {
              return (
                <Col key={index} xxl={4} xl={6} lg={8} md={12} sm={12} xs={24}>
                  <AppPreview app={app} key={app.appId} currentUser={props.currentUser} />
                </Col>
              );
            })
          }
        </Row>
      </div>
      <ListPagination
        activeTag={props.activeTag}
        pager={props.pager}
        appsCount={props.appsCount}
        currentPage={props.currentPage} />
    </section>





  );
};

export default AppList;
