import React, { Fragment } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom'

import agent from "../../agent";

import { DASHBOARD_PAGE_LOAD, DASHBOARD_PAGE_UNLOAD } from "../../constants/actionTypes";

const mapStateToProps = state => ({
    ...state.dashboard,
    ...state.school,
    token: state.common.token,
    currentUser: state.common.currentUser,
});

const mapDispatchToProps = dispatch => ({
    onLoad: (pager, payload) => dispatch({ type: DASHBOARD_PAGE_LOAD, pager, payload }),
    onUnload: (pager, payload) => dispatch({ type: DASHBOARD_PAGE_UNLOAD, pager, payload })
});

// const Dashboard = props => (
class Dashboard extends React.Component {

    componentWillMount() {
        this.props.onLoad(agent.Dashbaord.meta, Promise.all([agent.Dashbaord.meta()]));
    }
    componentWillUnmount() {
        this.props.onUnload();
    }

    render() {
        return <Fragment>
            <main id="tg-main" className="tg-main tg-dashboard">
                <div className="tg-dashboardcontent">
                    {this.props.token && this.props.currentUser.role === 'admin' ?
                        <aside id="tg-leftsidebar" className="tg-leftsidebar">
                            <div className="tg-verticalscrollbar tg-leftsidebarscroll">

                                <div className="tg-counters">
                                    <div className="tg-counter">
                                        <div className="tg-counterbox">
                                            <Link to="/users">
                                                <h4 data-from={0} data-to={305} data-speed={8000} data-refresh-interval={50}>{this.props.usersCount}</h4>
                                                <h5>Registered Users</h5>
                                            </Link>
                                        </div>
                                    </div>
                                    {/* <div className="tg-counter">
                                    <div className="tg-counterbox">
                                        <h4 data-from={0} data-to={76} data-speed={8000} data-refresh-interval={50}>{this.props.appsCount}</h4>
                                        <h5>Add School</h5>
                                    </div>
                                </div> */}
                                    <div className="tg-counter">
                                        <div className="tg-counterbox">
                                            <h4 data-from={0} data-to={76} data-speed={8000} data-refresh-interval={50}>{this.props.appsCount}</h4>
                                            <h5>Total Apps in store</h5>
                                        </div>
                                    </div>
                                    {/* <div className="tg-counter">
                                    <i></i>
                                    <div className="tg-counterbox">
                                        <h4 data-from={0} data-to={150} data-speed={8000} data-refresh-interval={50}>123</h4>
                                        <h5>Popular Apps</h5>
                                    </div>
                                </div> */}
                                </div>

                            </div>
                        </aside>
                        : this.props.token && this.props.currentUser.role === 'schooladmin' ? <aside id="tg-leftsidebar" className="tg-leftsidebar">
                            <div className="tg-verticalscrollbar tg-leftsidebarscroll">

                                <div className="tg-counters">
                                    <div className="tg-counter">
                                        <div className="tg-counterbox">
                                            <Link to={`/users/${this.props.currentUser.school}`}>
                                                {/* <h4 data-from={0} data-to={305} data-speed={8000} data-refresh-interval={50}>{this.props.usersCount}</h4> */}
                                                <h5>Manage Users</h5>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </aside> : null
                    }

                    <div id="tg-content" className="tg-content">
                        <div className="tg-verticalscrollbar">
                            {this.props.children}
                        </div>
                    </div>
                </div>


            </main>

            <footer id="tg-footer" className="tg-footer tg-footersetting tg-haslayout">
                <div className="tg-footerbar">
                    <span className="tg-copyright"> TouchIT Technologies © {(new Date().getFullYear())}. All rights reserved.</span>
                    <ul className="tg-socialicons">
                        <li className="tg-facebook"><a href="https://www.facebook.com/touchittechnologies" target="_blank" rel="noopener noreferrer" ><i className="fa fa-facebook" /></a></li>
                        <li className="tg-twitter"><a href="https://twitter.com/touchit_tech" target="_blank" rel="noopener noreferrer" ><i className="fa fa-twitter" /></a></li>
                        <li className="tg-youtube"><a href="https://www.youtube.com/user/TouchITtechnologies" target="_blank" rel="noopener noreferrer" ><i className="fa fa-youtube" /></a></li>
                        <li className="tg-linkedin"><a href="https://vimeo.com/touchittechnologies" target="_blank" rel="noopener noreferrer" ><i className="fa fa-vimeo" /></a></li>
                    </ul>
                </div>
            </footer>
        </Fragment>
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);