import {
  APP_FAVORITED,
  APP_UNFAVORITED,
  SET_PAGE,
  APPLY_TAG_FILTER,
  HOME_PAGE_LOADED,
  HOME_PAGE_UNLOADED,
  CHANGE_TAB,
  PROFILE_PAGE_LOADED,
  PROFILE_PAGE_UNLOADED,
  PROFILE_FAVORITES_PAGE_LOADED,
  PROFILE_FAVORITES_PAGE_UNLOADED,
  UPDATE_FIELD_SEARCH_FORM,
  SEARCH_FORM_SUBMITTED
} from '../constants/actionTypes';

const defaultState = {
  tab: 'all'
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case APP_FAVORITED:
    case APP_UNFAVORITED:
      return {
        ...state,
        apps: state.apps.map(app => {
          if (app.appId === action.payload.app.appId) {
            return {
              ...app,
              favorited: action.payload.app.favorited,
              favoritesCount: action.payload.app.favoritesCount
            };
          }
          return app;
        })
      };
    case SET_PAGE:
      return {
        ...state,
        apps: action.payload.apps,
        appsCount: action.payload.appsCount,
        currentPage: action.page
      };
    case APPLY_TAG_FILTER:
      return {
        ...state,
        pager: action.pager,
        apps: action.payload.apps,
        appsCount: action.payload.appsCount,
        tab: null,
        tag: action.tag,
        currentPage: 0
      };
    case HOME_PAGE_LOADED:
      return {
        ...state,
        pager: action.pager,
        tags: action.payload[0].tags,
        apps: action.payload[1].apps,
        appsCount: action.payload[1].appsCount,
        currentPage: 0,
        tab: action.tab,
        search: '',
      };
    case HOME_PAGE_UNLOADED:
      return {};
    case CHANGE_TAB:
      return {
        ...state,
        pager: action.pager,
        apps: action.payload.apps,
        appsCount: action.payload.appsCount,
        tab: action.tab,
        currentPage: 0,
        tag: null
      };
    case PROFILE_PAGE_LOADED:
    case PROFILE_FAVORITES_PAGE_LOADED:
      return {
        ...state,
        pager: action.pager,
        apps: action.payload[1].apps,
        appsCount: action.payload[1].appsCount,
        currentPage: 0
      };
    case UPDATE_FIELD_SEARCH_FORM:
      return { ...state, [action.key]: action.value };
    case SEARCH_FORM_SUBMITTED:
      return { ...state, [action.key]: action.value };

    case PROFILE_PAGE_UNLOADED:
    case PROFILE_FAVORITES_PAGE_UNLOADED:
      return {};
    default:
      return state;
  }
};
