import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames'
import s from './Alert.module.css';
import smile from './icon-smile.png'
import close from './close-icon.png'
import bgImg from './popup-img.jpg'

class Alert extends Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        title: PropTypes.string,
        size: PropTypes.string,
        onOK: PropTypes.func,
        onCancel: PropTypes.func,
        okButtonText: PropTypes.string,
        cancelButtonText: PropTypes.string,
        alertMessage: PropTypes.string,
        onToggle: PropTypes.func.isRequired,
    };

    static defaultProps = {
        title: '',
        size: 'sm',
        isOpen: false,
        okButtonText: 'Yes',
        cancelButtonText: 'No',
        alertMessage: 'Are you sure ?',
        onToggle: () => { },
    };


    render() {
        const {
            title,
            size,
            isOpen,
            onToggle,
            alertMessage,
            onOK,
            onCancel,
            okButtonText,
            cancelButtonText
        } = this.props;

        return (
            <div className={cx(s["at-popupcontainer"], { [s['at-openpopup']]: isOpen })}>
                <div className={cx(s["at-popup"], { [s["at-md"]]: size === 'md', [s["at-sm"]]: size !== 'md' })}>
                    <figure className={s["at-popupimg"]}>
                        {title}
                        <span onClick={onToggle} className={s["at-closepopup"]}>
                            <img src={close} alt="description" />
                        </span>
                        <img src={bgImg} alt="description" />
                    </figure>

                    <div className={s["at-popupcontent"]}>
                        <span className={s["at-popupicon"]}><img src={smile} alt="description" /></span>
                        <h2>{alertMessage}</h2>
                        {this.props.children}
                    </div>
                    <div>
                        <button type="submit" className={cx("btn at-themebtn pull-right")} onClick={onOK}>{okButtonText}</button>
                        <button type="button" className={cx("btn at-themebtn pull-right")} onClick={onCancel || onToggle}>{cancelButtonText}</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Alert;
