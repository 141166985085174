import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import TextFieldGroup from './common/TextFieldGroup/TextFieldGroup'
import agent from '../agent';
import {
  UPDATE_FIELD_AUTH,
  LOGIN_PAGE_UNLOADED,
  RESET_PASSWORD_REQUEST
} from '../constants/actionTypes';

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
  onChangeEmail: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'email', value }),
  onSubmit: (email, password) =>
    dispatch({ type: RESET_PASSWORD_REQUEST, payload: agent.Auth.resetPasswordRequest(email, password) }),
  onUnload: () =>
    dispatch({ type: LOGIN_PAGE_UNLOADED })
});

class Login extends React.Component {
  constructor() {
    super();
    this.changeEmail = ev => this.props.onChangeEmail(ev.target.value);
    this.submitForm = (email) => ev => {
      ev.preventDefault();
      this.props.onSubmit(email);
    };
  }


  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const errors = this.props.errors || {};
    const message = this.props.message;
    const email = this.props.email;
    return (<div className="tg-fullscreen tg-fullbackground tg-loginarea" id="tg-fullbackground">
      <div style={{ position: 'absolute', zIndex: -1, top: 0, left: 0, bottom: 0, right: 0, overflow: 'hidden', backgroundSize: 'cover', backgroundColor: 'transparent', backgroundRepeat: 'no-repeat', backgroundPosition: '50% 100%', backgroundImage: 'url("images/banner/img-04.jpg")' }}></div>

      <main id="tg-main" className="tg-main tg-haslayout">
        <div className="tg-formarea">
          <form onSubmit={this.submitForm(email)} className="tg-formtheme tg-formresetkey">
            <h2>Forgot Password</h2>

            <fieldset>
              {message && <div className="alert alert-success">{message}</div>}
              {this.props.errors && <div className="alert alert-danger">{this.props.errors.form}</div>}

              <TextFieldGroup
                error={errors.email}
                label="Email"
                onChange={this.changeEmail}
                value={email}
                field="email"
                type="email"
              />

              <div className="form-group">
                <button type="submit" className="tg-btn" disabled={this.props.inProgress}>Send Reset Link</button>
              </div>


              <div className="form-group">
                <span>If You Have an Account? <Link to="/login">Signin</Link></span>
              </div>

            </fieldset>
          </form>
        </div>
      </main>
    </div>);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);