import DeleteButton from './DeleteButton';
import { Link } from 'react-router-dom';
import React from 'react';

const Comment = props => {
  const comment = props.comment;
  const show = props.currentUser &&
    props.currentUser.username === comment.author.username;

  return (
    <li>
      <div className="tg-appreview">

        <figure><img src={`https://dummyimage.com/40x40/3876b4/ffffff.png&text= ${comment.author.username}`} alt={`${comment.author.username}`} /></figure>

        <div className="tg-appreviewcontent">
          <h5>{comment.author.username}</h5>

          <div className="tg-appratingandcounts">
            <time dateTime={new Date(comment.createdAt).toDateString()}>{new Date(comment.createdAt).toDateString()}</time>
          </div>

          <div className="tg-description">
            <p>{comment.body}</p>
          </div>


          <DeleteButton show={show} slug={props.slug} commentId={comment.id} />
        </div>


      </div>
    </li>
  );
};

export default Comment;
