import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames'

class TextFieldGroup extends Component {
    static propTypes = {
        field: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        autoFocus: PropTypes.bool.isRequired,
        isDisabled: PropTypes.bool.isRequired,

        type: PropTypes.string,
        error: PropTypes.string,
        onBlur: PropTypes.func,
        onFocus: PropTypes.func,

    };

    static defaultProps = {
        type: 'text',
        autoFocus: false,
        isDisabled: false,
        error: '',
        onBlur: () => true,
        onFocus: () => true,
    };

    render() {
        const {
            field,
            value,
            label,
            error,
            type,
            onChange,
            onBlur,
            onFocus,
            autoFocus,
            isDisabled
        } = this.props;
        return (
            <div className={cx('form-group', { 'has-error': error })}>
                {/* <label>{label} */}
                <input
                    placeholder={label}
                    onChange={onChange}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    value={value}
                    type={type}
                    name={field}
                    autoFocus={autoFocus}
                    id={field}
                    className="form-control"
                    disabled={isDisabled}
                />
                {/* </label> */}
                {error && <span className={'help-block pull-left'}>{label} {error}</span>}
            </div>
        );
    }
}

export default TextFieldGroup;


