import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { HOST } from '../../../config'

const mapStateToProps = state => ({
    ...state.app,
    currentUser: state.common.currentUser
});

class DetailPage extends React.Component {
    componentDidMount() {
        window.jQuery(this.hs).pogoSlider({
            autoplay: false,
            responsive: true,
            targetHeight: 445,
            generateNav: false,
            pauseOnHover: false,
            displayProgess: true,
            autoplayTimeout: 6000,
        }).data('plugin_pogoSlider');
    }

    render() {
        let appId = '';
        let title = '';
        let summary = '';
        let platform = [];
        if (this.props.app) {
            appId = this.props.app.appId
            title = this.props.app.title
            summary = this.props.app.summary
            platform = this.props.app.platform || []
        }
        return (<div ref={hs => this.hs = hs} id="nsg-detailslider" className="nsg-detailslider tg-haslayout">
            <div className="pogoSlider-slide" data-transition="fade" data-duration="1500" style={{ "background": "url(images/banner/img-02.jpg) 0px 0px no-repeat scroll" }}>
                <div className="nsg-slidecontent">
                    <h1 className="pogoSlider-slide-element" data-in="slideDown" data-out="slideUp" data-duration="1800" data-delay="500">{title}</h1>
                    <div className="tg-description pogoSlider-slide-element" data-in="slideLeft" data-out="slideRight" data-duration="1800" data-delay="500">
                        <p>{summary}</p>
                    </div>

                    <div className="tg-description pogoSlider-slide-element" data-in="slideLeft" data-out="slideRight" data-duration="1800" data-delay="500">
                        Supported For <p>{platform.join(' & ')}</p>
                    </div>

                    {this.props.currentUser ?
                        <a className="tg-btn pogoSlider-slide-element" data-in="slideUp" data-out="slideDown" data-duration="1800" data-delay="500" href={`${HOST}/apps/${appId}/${appId}.apk`}>Download App</a>
                        :
                        <Link className="tg-btn" to={'/login'}>download app</Link>
                    }
                </div>
            </div>
        </div>);
    };
}

export default connect(mapStateToProps, null)(DetailPage);

// export default DetailPage;