import React, { Fragment } from 'react';
import agent from '../../agent';

const Tags = props => {
  const tags = props.tags;
  if (tags) {
    return (
      <ul>
        {
          tags.map(tag => {
            const handleClick = ev => {
              ev.preventDefault();
              if (props.search) {
                props.onTabClick('search', agent.Applications.search, agent.Applications.search(1, props.search, tag));
              } else {
                props.onClickTag(tag, page => agent.Applications.byTag(tag, page), agent.Applications.byTag(tag));
              }
            };
            return (
              <li key={tag}><a
                href=""
                className={`${tag === props.active ? 'nsg-category-active nsg-category' : 'nsg-category'}`}
                onClick={handleClick}>
                {tag}
              </a></li>
            );
          })
        }
        {props.active ?
          <li className="tg-btnreset"><a
            href='javascript:void(0);'
            className='nsg-category-reset'
            onClick={props.onResetCategoryFilter}
          >
            Reset Category Filter
          </a></li> : null}
      </ul>
    );
  } else {
    return (
      <Fragment>Loading Tags...</Fragment>
    );
  }
};

export default Tags;
